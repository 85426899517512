/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsEsignceremonywitness from 'Models/Security/Acl/VisitorsEsignceremonywitness';
import UserEsignceremonywitness from 'Models/Security/Acl/UserEsignceremonywitness';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IEsignceremonywitnessEntityAttributes extends IModelAttributes {
	witnessstatus: Enums.esignwitnessstatus;
	signaturetimestamp: Date;
	witnessip: string;
	witnesslocation: string;

	esignceremonysigneeId: string;
	esignceremonysignee: Models.EsignceremonysigneeEntity | Models.IEsignceremonysigneeEntityAttributes;
	smsTokenId: string;
	smsToken: Models.SmsTokenEntity | Models.ISmsTokenEntityAttributes;
	esignuserId: string;
	esignuser: Models.EsignuserEntity | Models.IEsignuserEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('EsignceremonywitnessEntity', 'ESignCeremonyWitness')
// % protected region % [Customise your entity metadata here] end
export default class EsignceremonywitnessEntity extends Model
	implements IEsignceremonywitnessEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsEsignceremonywitness(),
		new UserEsignceremonywitness(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'WitnessStatus'] off begin
	@observable
	@attribute<EsignceremonywitnessEntity, Enums.esignwitnessstatus>()
	@CRUD({
		name: 'WitnessStatus',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.esignwitnessstatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.esignwitnessstatusOptions),
		displayFunction: (attr: Enums.esignwitnessstatus) => Enums.esignwitnessstatusOptions[attr],
	})
	public witnessstatus: Enums.esignwitnessstatus;
	// % protected region % [Modify props to the crud options here for attribute 'WitnessStatus'] end

	// % protected region % [Modify props to the crud options here for attribute 'SignatureTimeStamp'] off begin
	@observable
	@attribute<EsignceremonywitnessEntity, Date>()
	@CRUD({
		name: 'SignatureTimeStamp',
		displayType: 'datetimepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public signaturetimestamp: Date;
	// % protected region % [Modify props to the crud options here for attribute 'SignatureTimeStamp'] end

	// % protected region % [Modify props to the crud options here for attribute 'WitnessIP'] off begin
	@observable
	@attribute<EsignceremonywitnessEntity, string>()
	@CRUD({
		name: 'WitnessIP',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public witnessip: string;
	// % protected region % [Modify props to the crud options here for attribute 'WitnessIP'] end

	// % protected region % [Modify props to the crud options here for attribute 'WitnessLocation'] off begin
	@observable
	@attribute<EsignceremonywitnessEntity, string>()
	@CRUD({
		name: 'WitnessLocation',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public witnesslocation: string;
	// % protected region % [Modify props to the crud options here for attribute 'WitnessLocation'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignCeremonySignee'] off begin
		name: 'ESignCeremonySignee',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.EsignceremonysigneeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'esignceremonysignees',
			oppositeEntity: () => Models.EsignceremonysigneeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ESignCeremonySignee'] end
	})
	public esignceremonysigneeId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public esignceremonysignee: Models.EsignceremonysigneeEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'SMS Token'] off begin
		name: 'SMS Token',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.SmsTokenEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'smsTokens',
			oppositeEntity: () => Models.SmsTokenEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'SMS Token'] end
	})
	public smsTokenId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public smsToken: Models.SmsTokenEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignUser'] off begin
		name: 'ESignUser',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.EsignuserEntity,
		// % protected region % [Modify props to the crud options here for reference 'ESignUser'] end
	})
	public esignuserId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public esignuser: Models.EsignuserEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IEsignceremonywitnessEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IEsignceremonywitnessEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.witnessstatus !== undefined) {
				this.witnessstatus = attributes.witnessstatus;
			}
			if (attributes.signaturetimestamp !== undefined) {
				if (attributes.signaturetimestamp === null) {
					this.signaturetimestamp = attributes.signaturetimestamp;
				} else {
					this.signaturetimestamp = moment(attributes.signaturetimestamp).toDate();
				}
			}
			if (attributes.witnessip !== undefined) {
				this.witnessip = attributes.witnessip;
			}
			if (attributes.witnesslocation !== undefined) {
				this.witnesslocation = attributes.witnesslocation;
			}
			if (attributes.esignceremonysigneeId !== undefined) {
				this.esignceremonysigneeId = attributes.esignceremonysigneeId;
			}
			if (attributes.esignceremonysignee !== undefined) {
				if (attributes.esignceremonysignee === null) {
					this.esignceremonysignee = attributes.esignceremonysignee;
				} else if (attributes.esignceremonysignee instanceof Models.EsignceremonysigneeEntity) {
					this.esignceremonysignee = attributes.esignceremonysignee;
					this.esignceremonysigneeId = attributes.esignceremonysignee.id;
				} else {
					this.esignceremonysignee = new Models.EsignceremonysigneeEntity(attributes.esignceremonysignee);
					this.esignceremonysigneeId = this.esignceremonysignee.id;
				}
			}
			if (attributes.smsTokenId !== undefined) {
				this.smsTokenId = attributes.smsTokenId;
			}
			if (attributes.smsToken !== undefined) {
				if (attributes.smsToken === null) {
					this.smsToken = attributes.smsToken;
				} else if (attributes.smsToken instanceof Models.SmsTokenEntity) {
					this.smsToken = attributes.smsToken;
					this.smsTokenId = attributes.smsToken.id;
				} else {
					this.smsToken = new Models.SmsTokenEntity(attributes.smsToken);
					this.smsTokenId = this.smsToken.id;
				}
			}
			if (attributes.esignuserId !== undefined) {
				this.esignuserId = attributes.esignuserId;
			}
			if (attributes.esignuser !== undefined) {
				if (attributes.esignuser === null) {
					this.esignuser = attributes.esignuser;
				} else if (attributes.esignuser instanceof Models.EsignuserEntity) {
					this.esignuser = attributes.esignuser;
					this.esignuserId = attributes.esignuser.id;
				} else {
					this.esignuser = new Models.EsignuserEntity(attributes.esignuser);
					this.esignuserId = this.esignuser.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		esignceremonysignee {
			${Models.EsignceremonysigneeEntity.getAttributes().join('\n')}
		}
		smsToken {
			${Models.SmsTokenEntity.getAttributes().join('\n')}
		}
		esignuser {
			${Models.EsignuserEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'esignceremonysignee',
							'smsToken',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(EsignceremonywitnessEntity.prototype, 'created');
CRUD(modifiedAttr)(EsignceremonywitnessEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
