/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import UserBusinessentitypaymentmethodmanytomany from '../Security/Acl/UserBusinessentitypaymentmethodmanytomany';
import VisitorsBusinessentitypaymentmethodmanytomany from '../Security/Acl/VisitorsBusinessentitypaymentmethodmanytomany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IBusinessEntityPaymentMethodAttributes extends IModelAttributes {
	businessEntityId: string;
	paymentMethodId: string;

	businessEntity: Models.BusinessEntity | Models.IBusinessEntityAttributes;
	paymentMethod: Models.PaymentMethodEntity | Models.IPaymentMethodEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('BusinessEntityPaymentMethod')
export default class BusinessEntityPaymentMethod
	extends Model
	implements IBusinessEntityPaymentMethodAttributes {
	public static acls: IAcl[] = [
		new UserBusinessentitypaymentmethodmanytomany(),
		new VisitorsBusinessentitypaymentmethodmanytomany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public businessEntityId: string;

	@observable
	@attribute()
	public paymentMethodId: string;

	@observable
	@attribute({ isReference: true })
	public businessEntity: Models.BusinessEntity;

	@observable
	@attribute({ isReference: true })
	public paymentMethod: Models.PaymentMethodEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IBusinessEntityPaymentMethodAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.businessEntityId) {
				this.businessEntityId = attributes.businessEntityId;
			}
			if (attributes.paymentMethodId) {
				this.paymentMethodId = attributes.paymentMethodId;
			}

			if (attributes.businessEntity) {
				if (attributes.businessEntity instanceof Models.BusinessEntity) {
					this.businessEntity = attributes.businessEntity;
					this.businessEntityId = attributes.businessEntity.id;
				} else {
					this.businessEntity = new Models.BusinessEntity(attributes.businessEntity);
					this.businessEntityId = this.businessEntity.id;
				}
			} else if (attributes.businessEntityId !== undefined) {
				this.businessEntityId = attributes.businessEntityId;
			}

			if (attributes.paymentMethod) {
				if (attributes.paymentMethod instanceof Models.PaymentMethodEntity) {
					this.paymentMethod = attributes.paymentMethod;
					this.paymentMethodId = attributes.paymentMethod.id;
				} else {
					this.paymentMethod = new Models.PaymentMethodEntity(attributes.paymentMethod);
					this.paymentMethodId = this.paymentMethod.id;
				}
			} else if (attributes.paymentMethodId !== undefined) {
				this.paymentMethodId = attributes.paymentMethodId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
