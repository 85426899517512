/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

// % protected region % [Override contents here] off begin
export const SERVER_URL = '';
// % protected region % [Override contents here] end

// % protected region % [Override application name here] off begin
export const APPLICATION_NAME = 'access';
// % protected region % [Override application name here] end

// % protected region % [Add any extra constants here] on begin
/**
 * Constants to track session keys for various pages.
 *
 * Semantics
 * - <application name>__<page name>__<description>
 * - hyphen separater for spaced words
 */
export const SessionKeys = {
	/**
	 * Tracks the user's selected filters and sorts on the customer list page
	 */
	customerListPageConfig: `${APPLICATION_NAME}__customer-list-page__config`,
};
// % protected region % [Add any extra constants here] end
