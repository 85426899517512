/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

// % protected region % [Add extra imports here] on begin
import axios from 'axios';
import { LiquorLicenceValidationResponse } from '../Types/liquorLicenceValidationResponse';
import { state } from 'Models/Enums';
import { SERVER_URL } from '../Constants';
// % protected region % [Add extra imports here] end

export function lowerCaseFirst(str: string) {
	return str[0].toLowerCase() + str.slice(1);
}

export function upperCaseFirst(str: string) {
	return str[0].toUpperCase() + str.slice(1);
}

export function camelCaseIntoWords(name: string) {
	return name.replace(/([A-Z])/g, ' $1')
		.replace(/^./, str => str.toUpperCase());
}

export function camelCase(str: string) {
	const words = str.split(' ');
	const allLowerCase = words.map(w => w.toLowerCase());
	return allLowerCase[0] + allLowerCase.slice(1).map(upperCaseFirst).join('');
}

export function lowerCaseNoSpaces(str: string) {
	return str.toLowerCase().replace(/ /g, '');
}

export function noSpaces(str: string) {
	return str.replace(/ /g, '');
}

/**
 * Formats a number of bytes as a string
 * Implementation taken from https://stackoverflow.com/a/18650828/8426638
 * @param bytes The byte amount to format
 * @param decimals The number of decimals allowed
 */
export function formatBytes(bytes: number, decimals = 2) {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	// eslint-disable-next-line prefer-template
	return parseFloat((bytes / (k ** i)).toFixed(dm)) + ' ' + sizes[i];
}

// % protected region % [Add extra StringUtil functions here] on begin
export function formatAbn(abn?: string) {
	if (abn && abn.length >= 11) {
		return `${abn.slice(0, 2)} ${abn.slice(2, 5)} ${abn.slice(5, 8)} ${abn.slice(8)}`;
	}
	return abn;
}

export function LocaleCompareAsc(a: string, b: string): number {
	return a.localeCompare(b);
}
export function LocaleCompareAttrAsc(attr: string): (a: any, b: any) => number {
	return (a, b) => LocaleCompareAsc(a[attr], b[attr]);
}
export function LocaleCompareObjectAsc(obj: { [key: string]: string }): (a: string, b: string) => number {
	return (a, b) => LocaleCompareAsc(obj[a], obj[b]);
}

const numberFormatWithSymbol = Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'AUD',
	currencyDisplay: 'narrowSymbol',
});
const numberFormatWithoutSymbol = Intl.NumberFormat('en-US', {
	style: 'decimal',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});
const totalNumberDecimalFormat = Intl.NumberFormat('en-US', {
	style: 'decimal',
});
export function LocaleFormatCurrency(amount: number, includeSymbol: boolean = true): string {
	return includeSymbol
		? numberFormatWithSymbol.format(amount)
		: numberFormatWithoutSymbol.format(amount);
}
export function LocaleFormatNumberDecimal(amount: number): string {
	return totalNumberDecimalFormat.format(amount);
}

export function safeGqlSearch(search: string) {
	return search.replace(/["\\]/g, '');
}

// function to validate the abn by calling validation function from backend.
// we return the response from the server if successfull and return a formatted
// exception object if there was an exception
export function verifyAbn(abn: string): Promise<any> {
	return axios.post(`${SERVER_URL}/api/registration/verifyAbn`, {
		searchString: abn,
	}).then(data => {
		return data.data.response;
	}).catch(exception => {
		return { exception: { exceptionDescription: exception.response.data } };
	});
}

// function to validate the acn by calling validation function from backend.
// we return the response from the server if successfull and return a formatted
// exception object if there was an exception
export function verifyAcn(acn: string): Promise<any> {
	return axios.post(`${SERVER_URL}/api/registration/verifyAcn`, {
		searchString: acn,
	}).then(data => {
		return data.data;
	}).catch(exception => {
		return { exception: { exceptionDescription: exception.response.data } };
	});
}

// eslint-disable-next-line max-len
export function validateLiquorLicenceNumber(licenceNumber: string, licenceState: string, customerId?: string) {
	return axios.post(`${SERVER_URL}/api/liquor-licence-validation`, {
		licenceNumber,
		state: licenceState,
		customerId: customerId,
	}).then(data => {
		return { data: data.data } as LiquorLicenceValidationResponse;
	}).catch(exception => {
		return { exception: { exceptionDescription: exception.response.data } } as LiquorLicenceValidationResponse;
	});
}

export function isTypeOfState(value: string): value is state {
	return ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'].includes(value);
}

export function pluralise(count: number, word: string, pluralPart: string = 's', includeCount: boolean = true): string {
	if (count === 1) {
		return `${includeCount ? count : ''} ${word}`.trim();
	}

	const removeTrailingCharCount = pluralPart.lastIndexOf('.') + 1;

	let wordPlural = removeTrailingCharCount > 0
		? word.substring(0, word.length - removeTrailingCharCount)
		: word;

	wordPlural += pluralPart.substring(removeTrailingCharCount);

	return `${includeCount ? count : ''} ${wordPlural}`.trim();
}

export function isNumeric(n: string) {
	return !Number.isNaN(n) && !Number.isNaN(parseFloat(n));
}

export const EmptyGuid: string = '00000000-0000-0000-0000-000000000000';

export function booleanToActive(bool?: boolean): string {
	return bool === true ? 'Active' : 'Inactive';
}
// % protected region % [Add extra StringUtil functions here] end
