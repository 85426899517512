/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserProvider from 'Models/Security/Acl/UserProvider';
import VisitorsProvider from 'Models/Security/Acl/VisitorsProvider';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IProviderEntityAttributes extends IModelAttributes {
	name: string;
	description: string;
	isActive: boolean;
	configurationOne: string;
	configurationTwo: string;
	configurationThree: string;

	providerProducts: Array<
		| Models.ProviderProductEntity
		| Models.IProviderProductEntityAttributes
	>;
	organisations: Array<
		| Models.OrganisationProvider
		| Models.IOrganisationProviderAttributes
	>;
	providerTypeId: string;
	providerType: Models.ProviderTypeEntity | Models.IProviderTypeEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ProviderEntity', 'Provider')
// % protected region % [Customise your entity metadata here] end
export default class ProviderEntity extends Model
	implements IProviderEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserProvider(),
		new VisitorsProvider(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<ProviderEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	@observable
	@attribute<ProviderEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] off begin
	@observable
	@attribute<ProviderEntity, boolean>()
	@CRUD({
		name: 'Is Active',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isActive: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] end

	// % protected region % [Modify props to the crud options here for attribute 'Configuration One'] off begin
	@observable
	@attribute<ProviderEntity, string>()
	@CRUD({
		name: 'Configuration One',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public configurationOne: string;
	// % protected region % [Modify props to the crud options here for attribute 'Configuration One'] end

	// % protected region % [Modify props to the crud options here for attribute 'Configuration Two'] off begin
	@observable
	@attribute<ProviderEntity, string>()
	@CRUD({
		name: 'Configuration Two',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public configurationTwo: string;
	// % protected region % [Modify props to the crud options here for attribute 'Configuration Two'] end

	// % protected region % [Modify props to the crud options here for attribute 'Configuration Three'] off begin
	@observable
	@attribute<ProviderEntity, string>()
	@CRUD({
		name: 'Configuration Three',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public configurationThree: string;
	// % protected region % [Modify props to the crud options here for attribute 'Configuration Three'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Provider Product'] off begin
		name: 'Provider Products',
		displayType: 'reference-multicombobox',
		order: 70,
		referenceTypeFunc: () => Models.ProviderProductEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'providerProducts',
			oppositeEntity: () => Models.ProviderProductEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Provider Product'] end
	})
	public providerProducts: Models.ProviderProductEntity[] = [];

	/**
	 * Providers the organisation has access to
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-multicombobox',
		order: 80,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.OrganisationProvider,
		optionEqualFunc: makeJoinEqualsFunc('organisationId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'providerEntity',
			oppositeEntityName: 'organisationEntity',
			relationName: 'provider',
			relationOppositeName: 'organisation',
			entity: () => Models.ProviderEntity,
			joinEntity: () => Models.OrganisationProvider,
			oppositeEntity: () => Models.OrganisationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisations: Models.OrganisationProvider[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Provider Type'] off begin
		name: 'Provider Type',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.ProviderTypeEntity,
		// % protected region % [Modify props to the crud options here for reference 'Provider Type'] end
	})
	public providerTypeId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public providerType: Models.ProviderTypeEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IProviderEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IProviderEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.isActive !== undefined) {
				this.isActive = attributes.isActive;
			}
			if (attributes.configurationOne !== undefined) {
				this.configurationOne = attributes.configurationOne;
			}
			if (attributes.configurationTwo !== undefined) {
				this.configurationTwo = attributes.configurationTwo;
			}
			if (attributes.configurationThree !== undefined) {
				this.configurationThree = attributes.configurationThree;
			}
			if (attributes.providerProducts !== undefined && Array.isArray(attributes.providerProducts)) {
				for (const model of attributes.providerProducts) {
					if (model instanceof Models.ProviderProductEntity) {
						this.providerProducts.push(model);
					} else {
						this.providerProducts.push(new Models.ProviderProductEntity(model));
					}
				}
			}
			if (attributes.organisations !== undefined && Array.isArray(attributes.organisations)) {
				for (const model of attributes.organisations) {
					if (model instanceof Models.OrganisationProvider) {
						this.organisations.push(model);
					} else {
						this.organisations.push(new Models.OrganisationProvider(model));
					}
				}
			}
			if (attributes.providerTypeId !== undefined) {
				this.providerTypeId = attributes.providerTypeId;
			}
			if (attributes.providerType !== undefined) {
				if (attributes.providerType === null) {
					this.providerType = attributes.providerType;
				} else if (attributes.providerType instanceof Models.ProviderTypeEntity) {
					this.providerType = attributes.providerType;
					this.providerTypeId = attributes.providerType.id;
				} else {
					this.providerType = new Models.ProviderTypeEntity(attributes.providerType);
					this.providerTypeId = this.providerType.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		organisations {
			${Models.OrganisationProvider.getAttributes().join('\n')}
			organisation {
				${Models.OrganisationEntity.getAttributes().join('\n')}
				${Models.OrganisationEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		providerProducts {
			${Models.ProviderProductEntity.getAttributes().join('\n')}
		}
		providerType {
			${Models.ProviderTypeEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			organisations: {},
			providerProducts: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'organisations',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ProviderEntity.prototype, 'created');
CRUD(modifiedAttr)(ProviderEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
