/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserCustomerAudit from 'Models/Security/Acl/UserCustomerAudit';
import UserMonitoredEntityAudit from 'Models/Security/Acl/UserMonitoredEntityAudit';
import VisitorsMonitoredEntityAudit from 'Models/Security/Acl/VisitorsMonitoredEntityAudit';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IMonitoredentityauditEntityAttributes extends IModelAttributes {
	description: string;
	category: Enums.auditCategory;
	eventId: string;

	monitorId: string;
	monitor: Models.MonitoredEntity | Models.IMonitoredEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('MonitoredentityauditEntity', 'MonitoredEntityAudit')
// % protected region % [Customise your entity metadata here] end
export default class MonitoredentityauditEntity extends Model
	implements IMonitoredentityauditEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserCustomerAudit(),
		new UserMonitoredEntityAudit(),
		new VisitorsMonitoredEntityAudit(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	@Validators.Required()
	@observable
	@attribute<MonitoredentityauditEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Category'] off begin
	@Validators.Required()
	@observable
	@attribute<MonitoredentityauditEntity, Enums.auditCategory>()
	@CRUD({
		name: 'Category',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.auditCategoryOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.auditCategoryOptions),
		displayFunction: (attr: Enums.auditCategory) => Enums.auditCategoryOptions[attr],
	})
	public category: Enums.auditCategory;
	// % protected region % [Modify props to the crud options here for attribute 'Category'] end

	// % protected region % [Modify props to the crud options here for attribute 'Event Id'] off begin
	@observable
	@attribute<MonitoredentityauditEntity, string>()
	@CRUD({
		name: 'Event Id',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
	})
	public eventId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Event Id'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Monitor'] off begin
		name: 'Monitor',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.MonitoredEntity,
		// % protected region % [Modify props to the crud options here for reference 'Monitor'] end
	})
	public monitorId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public monitor: Models.MonitoredEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IMonitoredentityauditEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IMonitoredentityauditEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.category !== undefined) {
				this.category = attributes.category;
			}
			if (attributes.eventId !== undefined) {
				this.eventId = attributes.eventId;
			}
			if (attributes.monitorId !== undefined) {
				this.monitorId = attributes.monitorId;
			}
			if (attributes.monitor !== undefined) {
				if (attributes.monitor === null) {
					this.monitor = attributes.monitor;
				} else if (attributes.monitor instanceof Models.MonitoredEntity) {
					this.monitor = attributes.monitor;
					this.monitorId = attributes.monitor.id;
				} else {
					this.monitor = new Models.MonitoredEntity(attributes.monitor);
					this.monitorId = this.monitor.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		monitor {
			${Models.MonitoredEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(MonitoredentityauditEntity.prototype, 'created');
CRUD(modifiedAttr)(MonitoredentityauditEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
