import { observable } from 'mobx';
import { UserEntity } from 'Models/Entities/index';
import OrganisationEntity from 'Models/Entities/OrganisationEntity';
import _ from 'lodash';
import { jsonReplacerFn, Model, ReferencePath } from 'Models/Model';
import { serialiseDateTime } from '../../Util/AttributeUtils';

// The type for User Permissions
export default class ReferralRegistrationData {
	@observable public user?: UserEntity;
	@observable public organisation?: OrganisationEntity;
	@observable public page?: number;
	@observable public termsAccepted?: boolean;
	@observable public continueEmailSent: boolean = false;

	constructor(attributes?: Partial<ReferralRegistrationData>) {
		if (attributes) {
			if (attributes.user !== undefined) {
				this.user = new UserEntity(attributes.user);
			}
			if (attributes.organisation !== undefined) {
				this.organisation = new OrganisationEntity(attributes.organisation);
			}
			if (attributes.page !== undefined) {
				this.page = attributes.page;
			}
			if (attributes.termsAccepted !== undefined) {
				this.termsAccepted = attributes.termsAccepted;
			}
			if (attributes.continueEmailSent !== undefined) {
				this.continueEmailSent = attributes.continueEmailSent;
			}
		}
	}

	public toJSON(
		path: ReferencePath = {},
		replacer: jsonReplacerFn | undefined = undefined,
	) {
		const json = {};
		const pathKeys = Object.keys(path);

		const allKeys = _.uniq(Object.keys(this).concat(pathKeys));
		for (const key of allKeys) {
			if (this[key] === null) {
				json[key] = null;
				// eslint-disable-next-line no-continue
				continue;
			}

			switch (typeof (this[key])) {
				case 'function':
					// We never want functions
					break;
				case 'object':
					// Format dates as strings
					if (this[key] instanceof Date) {
						json[key] = serialiseDateTime(this[key]);
						break;
					}

					// We only want objects if they are defined in the provided path
					if (pathKeys.indexOf(key) >= 0) {
						if (Array.isArray(this[key])) {
							json[key] = this[key].map((model: Model | { [key: string]: unknown }) => {
								if (typeof model.toJSON === 'function') {
									return model.toJSON(path[key]);
								}
								return JSON.parse(JSON.stringify(model));
							});
						} else if (this[key] === null) {
							json[key] = null;
						} else if (typeof this[key].toJSON === 'function') {
							json[key] = this[key].toJSON(path[key]);
						} else {
							json[key] = JSON.parse(JSON.stringify(this[key]));
						}
					}
					break;
				case 'undefined':
					break;
				default:
					if (!key.startsWith('_')) {
						json[key] = this[key];
					}
			}
		}

		if (replacer) {
			return replacer(json);
		}
		return json;
	}
}
