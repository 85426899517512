/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
// % protected region % [Add any further imports here] on begin
import ReactTooltip, { Place } from 'react-tooltip';
// % protected region % [Add any further imports here] end

export interface ITooltipProps {
	id: string;
	content: React.ReactNode;
	// % protected region % [Add extra props here] on begin
	place?: Place
	// % protected region % [Add extra props here] end
}

export class Tooltip extends React.Component<ITooltipProps> {
	public render() {
		// % protected region % [Override render here] on begin
		const {
			id,
			content,
			place = 'bottom',
		} = this.props;
		return (
			<>
				<span
					className="tooltip icon-information-2 icon-right"
					data-for={id}
					data-tip={content}
				/>
				<ReactTooltip
					id={id}
					place={place}
					effect="solid"
					className="tooltip-box"
				/>
			</>
		);
		// % protected region % [Override render here] end
	}

	// % protected region % [Add extra class fields here] off begin
	// % protected region % [Add extra class fields here] end
}
