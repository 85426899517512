import * as Enums from './Enums';
import { cloneDeep } from 'lodash';
import { store } from './Store';
import { observable } from 'mobx';

// The type for User Permissions
export class UserPermissions {
	@observable public commonCaptureProspects?: boolean;
	@observable public commonExportUsers?: boolean;
	@observable public commonManageEntityPricing?: boolean;
	@observable public commonViewEntityPricing?: boolean;
	@observable public commonUpdatePaymentDetails?: boolean;
	@observable public commonCopyContract?: boolean;
	@observable public commonPackageUpgrade?: boolean;
	@observable public commonManageOrganisations?: Enums.organisationPermissionScope;
	@observable public commonViewOrganisations?: Enums.organisationPermissionScope;
	@observable public commonManageBusinessEntities?: Enums.businessEntitiesPermissionScope;
	@observable public commonViewBusinessEntities?: Enums.businessEntitiesPermissionScope;
	@observable public commonViewTransactions?: boolean;
	@observable public commonManageUsers?: Enums.userPermissionScope;
	@observable public commonViewUsers?: Enums.userPermissionScope;
	@observable public commonGenerateClientForms?: boolean;
	@observable public commonManageReferrals?: boolean;

	@observable public intelConnectToAccountingPackage?: boolean;
	@observable public intelUploadAtb?: boolean;
	@observable public intelCustomerOverview?: boolean;
	@observable public intelManageCustomerPpsrRegistrations?: boolean;
	@observable public intelManageCustomerGroups?: boolean;
	@observable public intelCreateCustomerGroups?: boolean;
	@observable public intelManageCustomerBusinessInformation?: boolean;
	@observable public intelManageCustomerAccountsInformation?: boolean;
	@observable public intelManageCustomerAgedDebtConfiguration?: boolean;
	@observable public intelViewCustomerPpsrRegistrations?: boolean;
	@observable public intelViewCustomerBusinessInformation?: boolean;
	@observable public intelViewCustomerAccountsInformation?: boolean;

	@observable public ppsrManageProfileTypes?: boolean;
	@observable public ppsrLinkProfileTypes?: boolean;
	@observable public ppsrViewProfileTypes?: boolean;
	@observable public ppsrCreateSpg?: boolean;
	@observable public ppsrManageSpg?: boolean;
	@observable public ppsrViewSpg?: boolean;
	@observable public ppsrViewRegistrations?: boolean;
	@observable public ppsrRegister?: boolean;
	@observable public ppsrDownloadRegistrations?: boolean;
	@observable public ppsrProcessBulkUpload?: boolean;
	@observable public ppsrSubmitForApprovalBulkUpload?: boolean;
	@observable public ppsrDischarge?: boolean;
	@observable public ppsrSearch?: boolean;
	@observable public ppsrViewSearches?: boolean;
	@observable public ppsrViewMySearches?: boolean;

	@observable public approveManageCreditApprovalLimit?: boolean;
	@observable public approveManageLesserCreditApprovalLimit?: boolean;
	@observable public approveManageTradeApplicationRules?: boolean;
	@observable public approveCreateTradeApplications?: boolean;
	@observable public approveAmendTradeApplications?: boolean;
	@observable public approvePreviewTradeApplications?: boolean;
	@observable public approveLinkTradeApplications?: boolean;
	@observable public approveGenerateCreditReport?: boolean;
	@observable public approveProcessPpsrWithinApplication?: boolean;
	@observable public approveSendApplicationsToCustomers?: boolean;
	@observable public approveViewCustomerApplications?: boolean;
	@observable public approveViewMyCustomerApplications?: boolean;
	@observable public approveRecommendApplicationsForApproval?: boolean;
	@observable public approveApproveApplications?: boolean;
	@observable public approveViewCustomerEmails?: boolean;
	@observable public approveDownloadApplicantApplications?: boolean;
	@observable public approveDownloadMyApplicantApplications?: boolean;

	// Ensure that typename is not stored
	constructor(attributes?: {[key: string]: any}) {
		if (attributes) {
			Object.keys(attributes).forEach(key => {
				if (key !== '__typename') {
					this[key] = attributes[key];
				}
			});
		}
	}

	// Permissions which are only visible to superAdmins
	public static hiddenPermissions: string[] = [
		'commonViewOrganisations', 'commonViewBusinessEntities',
	];
}

// The display form of the User Permissions
export const userPermissionsToDisplay = {
	commonCaptureProspects: 'Capture Prospects',
	commonExportUsers: 'Export Users',
	commonManageEntityPricing: 'Manage Entity Pricing',
	commonViewEntityPricing: 'View Entity Pricing',
	commonUpdatePaymentDetails: 'Update Payment Details',
	commonCopyContract: 'Copy Contract',
	commonPackageUpgrade: 'Package Upgrade',
	commonManageOrganisations: 'Manage Organisations',
	commonViewOrganisations: 'View Organisations',
	commonManageBusinessEntities: 'Manage Business Entities',
	commonViewBusinessEntities: 'View Business Entities',
	commonViewTransactions: 'View Transactions',
	commonManageUsers: 'Manage Users',
	commonViewUsers: 'View Users',
	commonGenerateClientForms: 'Generate Client Forms',
	commonManageReferrals: 'Manage Referrals',

	intelConnectToAccountingPackage: 'Connect To Accounting Package',
	intelUploadAtb: 'Upload Atb',
	intelCustomerOverview: 'Customer Overview',
	intelManageCustomerPpsrRegistrations: 'Manage Customer Ppsr Registrations',
	intelManageCustomerGroups: 'Customer Groups - Manage',
	intelCreateCustomerGroups: 'Customer Groups - Create',
	intelManageCustomerBusinessInformation: 'Manage Customer Business Information',
	intelManageCustomerAccountsInformation: 'Manage Customer Accounts Information',
	intelManageCustomerAgedDebtConfiguration: 'Manage Customer Aged Debt Configuration',
	intelViewCustomerPpsrRegistrations: 'View Customer Ppsr Registrations',
	intelViewCustomerBusinessInformation: 'View Customer Business Information',
	intelViewCustomerAccountsInformation: 'View Customer Accounts Information',

	ppsrManageProfileTypes: 'Manage Profile Types',
	ppsrLinkProfileTypes: 'Link Profile Types',
	ppsrViewProfileTypes: 'View Profile Types',
	ppsrCreateSpg: 'Create SPG',
	ppsrManageSpg: 'Manage SPG',
	ppsrViewSpg: 'View SPG',
	ppsrViewRegistrations: 'View Registrations',
	ppsrRegister: 'Register',
	ppsrDownloadRegistrations: 'Download Registrations',
	ppsrProcessBulkUpload: 'Process Bulk Upload',
	ppsrSubmitForApprovalBulkUpload: 'Submit For Approval Bulk Upload',
	ppsrDischarge: 'Discharge',
	ppsrSearch: 'Search',
	ppsrViewSearches: 'View Searches',
	ppsrViewMySearches: 'View My Searches',

	approveManageCreditApprovalLimit: 'Manage Credit Approval Limit',
	approveManageLesserCreditApprovalLimit: 'Manage Lesser Credit Approval Limit',
	approveManageTradeApplicationRules: 'Manage Trade Application Rules',
	approveCreateTradeApplications: 'Create Trade Applications',
	approveAmendTradeApplications: 'Amend Trade Applications',
	approvePreviewTradeApplications: 'Preview Trade Applications',
	approveLinkTradeApplications: 'Link Trade Applications',
	approveGenerateCreditReport: 'Generate Credit Report',
	approveProcessPpsrWithinApplication: 'Process PpsrWithin Application',
	approveSendApplicationsToCustomers: 'Send Applications To Customers',
	approveViewCustomerApplications: 'View Customer Applications',
	approveViewMyCustomerApplications: 'View My Customer Applications',
	approveRecommendApplicationsForApproval: 'Recommend Applications For Approval',
	approveApproveApplications: 'Approve Applications',
	approveViewCustomerEmails: 'View Customer Emails',
	approveDownloadApplicantApplications: 'Download Applicant Applications',
	approveDownloadMyApplicantApplications: 'Download My Applicant Applications',
};

/**
 * Returns the default permissions for a given user type
 * @param userType The userType for the default permissions
 * @returns The default permissions for the given userType
 */
export function defaultPermissionsForUserType(userType: Enums.userType): UserPermissions {
	return cloneDeep(store.defaultUserPermissions[userType]);
}

/**
 * Returns the calculated permissions for a user given their userType and what permissions they have overriden.
 * @param userType The userType for the permissions we are calculating
 * @param permissionOverrides The permissions that have been overriden
 * @returns The calculated permissions a user has given their usertype and the overriden permissions
 */
export function calculateUserPermissions(userType: Enums.userType, permissionOverrides?: UserPermissions)
		: UserPermissions {
	const userTypePermissions = defaultPermissionsForUserType(userType);

	// If they have no overriden permissions, return the default for the given userType
	if (!permissionOverrides) {
		return userTypePermissions;
	}

	// loop through all the permissions and calculate the necessary value
	for (const key in userTypePermissions) {
		if (permissionOverrides[key] !== undefined && permissionOverrides[key] !== null) {
			userTypePermissions[key] = permissionOverrides[key];
		}
	}

	return userTypePermissions;
}

/**
 * Returns the calculated permissions for a user given their userType and what permissions they have overriden.
 * @param userType The userType for the permissions we are calculating
 * @returns The calculated permissions a user has given their usertype and the overriden permissions
 */
export function calculateOtherUserTypeVisiblePermissions(userType: Enums.userType)
		: UserPermissions {
	const userTypePermissions = defaultPermissionsForUserType(userType);

	// loop through all the permissions and ensure that the permission does not exceed the controlling user
	for (const key in userTypePermissions[userType]) {
		if (!store.userPermissions[key]) {
			switch (typeof (store.userPermissions[key])) {
				case 'boolean':
					userTypePermissions[key] = false;
					break;
				case 'string':
					userTypePermissions[key] = 'SELF';
			}
		} else if (typeof (userTypePermissions[key]) === 'string') {
			if (Enums.userPermissionScopeOrder[userTypePermissions[key]]
				> Enums.userPermissionScopeOrder[store.userPermissions[key]]) {
				userTypePermissions[key] = store.userPermissions[key];
			}
		}
	}

	return userTypePermissions;
}
