/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { CaseComparison, CaseComparisonPascalCase, IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { ServerError, ServerParseError } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
// % protected region % [Add extra imports here] on begin
import { noSpaces, safeGqlSearch } from './StringUtils';
// % protected region % [Add extra imports here] end

/**
 * Check if a GraphQL error is a server error(instead of a GraphQL error)
 * @param error the returned error from server GraphQL
 * @param params The query params to use
 * @returns boolean, if the error is ServerError and have error['statusCode']
 */
export function isServerError(error: Error | ServerError | ServerParseError | undefined | null):
	error is ServerError | ServerParseError {
	return error !== undefined && error !== null && 'statusCode' in error;
}

export function getTheNetworkError(response: ErrorResponse): string | null {
	// Disable dot notation since it is very difficult to write it for type guards
	/* eslint-disable dot-notation */
	if (!!response.networkError && !!response.networkError['result']
		&& !!response.networkError['result']['errors']
		&& !!response.networkError['result']['errors']['length']) {
		const error = response.networkError['result']['errors'][0];
		return error['message'];
	}

	return null;
	/* eslint-enable dot-notation */
}

/**
 * Return an array of error messages from graphQl error response
 * @param response The GraphQL error response
 */
export function getErrorMessages(response: ErrorResponse): string[] {
	if (!!response.response && !!response.response.data) {
		if (!!response.response.data.errors && Array.isArray(response.response.data.errors)) {
			return response.response.data.errors;
		}

		return [JSON.stringify(response.response.data)];
	}
	return [];
}

/**
 * Checks if the condition has any OR conditions
 * A query has OR conditions if it is an array of arrays
 * @param conditions The condition to check
 */
export function isOrCondition<T>(
	conditions: Array<IWhereCondition<T>> | Array<Array<IWhereCondition<T>>> | undefined,
): conditions is Array<Array<IWhereCondition<T>>> {
	if (conditions === undefined) {
		return false;
	}

	if (conditions[0]) {
		return Array.isArray(conditions[0]);
	}
	return false;
}

/**
 * Converts a case comparison to its pascal case version
 * @param comparison The comparison to convert
 */
export function convertCaseComparisonToPascalCase(comparison: CaseComparison): CaseComparisonPascalCase {
	const comparisonMap: {[key in CaseComparison]: CaseComparisonPascalCase} = {
		CURRENT_CULTURE: 'CurrentCulture',
		CURRENT_CULTURE_IGNORE_CASE: 'CurrentCultureIgnoreCase',
		INVARIANT_CULTURE: 'InvariantCulture',
		INVARIANT_CULTURE_IGNORE_CASE: 'InvariantCultureIgnoreCase',
		ORDINAL: 'Ordinal',
		ORDINAL_IGNORE_CASE: 'OrdinalIgnoreCase',
	};

	return comparisonMap[comparison];
}

// % protected region % [Add any extra GraphQL utils here] on begin
/**
 * Builds the search conditions for a graphql search
 * @param columnsToSearch a list of column names for the columns we are searching
 * @param search the search string
 * @param comparison the comparison method
 */
export function buildSearchConditions(columnsToSearch: string[], search: string, comparison: CaseComparison) {
	const searchTermsWithSpaces = safeGqlSearch(search);
	const searchTermsWithoutSpaces = noSpaces(searchTermsWithSpaces);
	const searchConditions: IWhereCondition<string>[] = [];
	columnsToSearch.forEach(path => {
		searchConditions.push({
			case: comparison, comparison: 'like', path: path, value: [`%${searchTermsWithSpaces}%`],
		});
		searchConditions.push({
			case: comparison, comparison: 'like', path: path, value: [`%${searchTermsWithoutSpaces}%`],
		});
	});
	return searchConditions;
}
// % protected region % [Add any extra GraphQL utils here] end
