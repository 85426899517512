/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsEsignceremonysignee from 'Models/Security/Acl/VisitorsEsignceremonysignee';
import UserEsignceremonysignee from 'Models/Security/Acl/UserEsignceremonysignee';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IEsignceremonysigneeEntityAttributes extends IModelAttributes {
	signeestatus: Enums.esignceremonystatus;
	signaturetimestamp: Date;
	signeeip: string;
	signeelocation: string;
	templatecontent: string;

	smsTokenId: string;
	smsToken: Models.SmsTokenEntity | Models.ISmsTokenEntityAttributes;
	esignuserId: string;
	esignuser: Models.EsignuserEntity | Models.IEsignuserEntityAttributes;
	esignworkflowId: string;
	esignworkflow: Models.EsignworkflowEntity | Models.IEsignworkflowEntityAttributes;
	esignceremonywitness?: Models.EsignceremonywitnessEntity |
		Models.IEsignceremonywitnessEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('EsignceremonysigneeEntity', 'ESignCeremonySignee')
// % protected region % [Customise your entity metadata here] end
export default class EsignceremonysigneeEntity extends Model
	implements IEsignceremonysigneeEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsEsignceremonysignee(),
		new UserEsignceremonysignee(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'SigneeStatus'] off begin
	@observable
	@attribute<EsignceremonysigneeEntity, Enums.esignceremonystatus>()
	@CRUD({
		name: 'SigneeStatus',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.esignceremonystatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.esignceremonystatusOptions),
		displayFunction: (attr: Enums.esignceremonystatus) => Enums.esignceremonystatusOptions[attr],
	})
	public signeestatus: Enums.esignceremonystatus;
	// % protected region % [Modify props to the crud options here for attribute 'SigneeStatus'] end

	// % protected region % [Modify props to the crud options here for attribute 'SignatureTimeStamp'] off begin
	@observable
	@attribute<EsignceremonysigneeEntity, Date>()
	@CRUD({
		name: 'SignatureTimeStamp',
		displayType: 'datetimepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public signaturetimestamp: Date;
	// % protected region % [Modify props to the crud options here for attribute 'SignatureTimeStamp'] end

	// % protected region % [Modify props to the crud options here for attribute 'SigneeIP'] off begin
	@observable
	@attribute<EsignceremonysigneeEntity, string>()
	@CRUD({
		name: 'SigneeIP',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public signeeip: string;
	// % protected region % [Modify props to the crud options here for attribute 'SigneeIP'] end

	// % protected region % [Modify props to the crud options here for attribute 'SigneeLocation'] off begin
	@observable
	@attribute<EsignceremonysigneeEntity, string>()
	@CRUD({
		name: 'SigneeLocation',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public signeelocation: string;
	// % protected region % [Modify props to the crud options here for attribute 'SigneeLocation'] end

	// % protected region % [Modify props to the crud options here for attribute 'TemplateContent'] off begin
	@observable
	@attribute<EsignceremonysigneeEntity, string>()
	@CRUD({
		name: 'TemplateContent',
		displayType: 'textarea',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public templatecontent: string;
	// % protected region % [Modify props to the crud options here for attribute 'TemplateContent'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'SMS Token'] off begin
		name: 'SMS Token',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.SmsTokenEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'smsTokens',
			oppositeEntity: () => Models.SmsTokenEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'SMS Token'] end
	})
	public smsTokenId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public smsToken: Models.SmsTokenEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignUser'] off begin
		name: 'ESignUser',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.EsignuserEntity,
		// % protected region % [Modify props to the crud options here for reference 'ESignUser'] end
	})
	public esignuserId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public esignuser: Models.EsignuserEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignWorkflow'] off begin
		name: 'ESignWorkflow',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.EsignworkflowEntity,
		// % protected region % [Modify props to the crud options here for reference 'ESignWorkflow'] end
	})
	public esignworkflowId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public esignworkflow: Models.EsignworkflowEntity;

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignCeremonyWitness'] off begin
		name: 'ESignCeremonyWitness',
		displayType: 'displayfield',
		order: 90,
		inputProps: {
			displayFunction: (model?: Models.EsignceremonywitnessEntity) => model ? model.getDisplayName() : null,
		},
		// % protected region % [Modify props to the crud options here for reference 'ESignCeremonyWitness'] end
	})
	public esignceremonywitness?: Models.EsignceremonywitnessEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IEsignceremonysigneeEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IEsignceremonysigneeEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.signeestatus !== undefined) {
				this.signeestatus = attributes.signeestatus;
			}
			if (attributes.signaturetimestamp !== undefined) {
				if (attributes.signaturetimestamp === null) {
					this.signaturetimestamp = attributes.signaturetimestamp;
				} else {
					this.signaturetimestamp = moment(attributes.signaturetimestamp).toDate();
				}
			}
			if (attributes.signeeip !== undefined) {
				this.signeeip = attributes.signeeip;
			}
			if (attributes.signeelocation !== undefined) {
				this.signeelocation = attributes.signeelocation;
			}
			if (attributes.templatecontent !== undefined) {
				this.templatecontent = attributes.templatecontent;
			}
			if (attributes.smsTokenId !== undefined) {
				this.smsTokenId = attributes.smsTokenId;
			}
			if (attributes.smsToken !== undefined) {
				if (attributes.smsToken === null) {
					this.smsToken = attributes.smsToken;
				} else if (attributes.smsToken instanceof Models.SmsTokenEntity) {
					this.smsToken = attributes.smsToken;
					this.smsTokenId = attributes.smsToken.id;
				} else {
					this.smsToken = new Models.SmsTokenEntity(attributes.smsToken);
					this.smsTokenId = this.smsToken.id;
				}
			}
			if (attributes.esignuserId !== undefined) {
				this.esignuserId = attributes.esignuserId;
			}
			if (attributes.esignuser !== undefined) {
				if (attributes.esignuser === null) {
					this.esignuser = attributes.esignuser;
				} else if (attributes.esignuser instanceof Models.EsignuserEntity) {
					this.esignuser = attributes.esignuser;
					this.esignuserId = attributes.esignuser.id;
				} else {
					this.esignuser = new Models.EsignuserEntity(attributes.esignuser);
					this.esignuserId = this.esignuser.id;
				}
			}
			if (attributes.esignworkflowId !== undefined) {
				this.esignworkflowId = attributes.esignworkflowId;
			}
			if (attributes.esignworkflow !== undefined) {
				if (attributes.esignworkflow === null) {
					this.esignworkflow = attributes.esignworkflow;
				} else if (attributes.esignworkflow instanceof Models.EsignworkflowEntity) {
					this.esignworkflow = attributes.esignworkflow;
					this.esignworkflowId = attributes.esignworkflow.id;
				} else {
					this.esignworkflow = new Models.EsignworkflowEntity(attributes.esignworkflow);
					this.esignworkflowId = this.esignworkflow.id;
				}
			}
			if (attributes.esignceremonywitness !== undefined) {
				if (attributes.esignceremonywitness === null) {
					this.esignceremonywitness = attributes.esignceremonywitness;
				} else if (attributes.esignceremonywitness instanceof Models.EsignceremonywitnessEntity) {
					this.esignceremonywitness = attributes.esignceremonywitness;
				} else {
					this.esignceremonywitness = new Models.EsignceremonywitnessEntity(attributes.esignceremonywitness);
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		smsToken {
			${Models.SmsTokenEntity.getAttributes().join('\n')}
		}
		esignuser {
			${Models.EsignuserEntity.getAttributes().join('\n')}
		}
		esignworkflow {
			${Models.EsignworkflowEntity.getAttributes().join('\n')}
		}
		esignceremonywitness {
			${Models.EsignceremonywitnessEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'smsToken',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(EsignceremonysigneeEntity.prototype, 'created');
CRUD(modifiedAttr)(EsignceremonysigneeEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
