/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import UserOrganisationprovider from '../Security/Acl/UserOrganisationprovider';
import VisitorsOrganisationprovider from '../Security/Acl/VisitorsOrganisationprovider';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IOrganisationProviderAttributes extends IModelAttributes {
	organisationId: string;
	providerId: string;

	organisation: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	provider: Models.ProviderEntity | Models.IProviderEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('OrganisationProvider')
export default class OrganisationProvider
	extends Model
	implements IOrganisationProviderAttributes {
	public static acls: IAcl[] = [
		new UserOrganisationprovider(),
		new VisitorsOrganisationprovider(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public organisationId: string;

	@observable
	@attribute()
	public providerId: string;

	@observable
	@attribute({ isReference: true })
	public organisation: Models.OrganisationEntity;

	@observable
	@attribute({ isReference: true })
	public provider: Models.ProviderEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IOrganisationProviderAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.organisationId) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.providerId) {
				this.providerId = attributes.providerId;
			}

			if (attributes.organisation) {
				if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			} else if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}

			if (attributes.provider) {
				if (attributes.provider instanceof Models.ProviderEntity) {
					this.provider = attributes.provider;
					this.providerId = attributes.provider.id;
				} else {
					this.provider = new Models.ProviderEntity(attributes.provider);
					this.providerId = this.provider.id;
				}
			} else if (attributes.providerId !== undefined) {
				this.providerId = attributes.providerId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
