/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserIndustryCodesEntity from 'Models/Security/Acl/UserIndustryCodesEntity';
import VisitorsIndustryCodesEntity from 'Models/Security/Acl/VisitorsIndustryCodesEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { gql } from '@apollo/client';
import { store } from 'Models/Store';
import { computed, runInAction } from 'mobx';
// % protected region % [Add any further imports here] end

export interface IIndustryCodesEntityAttributes extends IModelAttributes {
	code: string;
	description: string;

	organisationss: Array<
		| Models.OrganisationEntity
		| Models.IOrganisationEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('IndustryCodesEntity', 'Industry Codes')
// % protected region % [Customise your entity metadata here] end
export default class IndustryCodesEntity extends Model
	implements IIndustryCodesEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserIndustryCodesEntity(),
		new VisitorsIndustryCodesEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Code'] off begin
	/**
	 * Code
	 */
	@observable
	@attribute<IndustryCodesEntity, string>()
	@CRUD({
		name: 'Code',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public code: string;
	// % protected region % [Modify props to the crud options here for attribute 'Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	/**
	 * Description
	 */
	@observable
	@attribute<IndustryCodesEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisations'] off begin
		name: 'Organisationss',
		displayType: 'reference-multicombobox',
		order: 30,
		referenceTypeFunc: () => Models.OrganisationEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'organisationss',
			oppositeEntity: () => Models.OrganisationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Organisations'] end
	})
	public organisationss: Models.OrganisationEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IIndustryCodesEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IIndustryCodesEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.code !== undefined) {
				this.code = attributes.code;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.organisationss !== undefined && Array.isArray(attributes.organisationss)) {
				for (const model of attributes.organisationss) {
					if (model instanceof Models.OrganisationEntity) {
						this.organisationss.push(model);
					} else {
						this.organisationss.push(new Models.OrganisationEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		organisationss {
			${Models.OrganisationEntity.getAttributes().join('\n')}
			${Models.OrganisationEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			organisationss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'organisationss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return `${this.code} - ${this.description}`;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	// We need Industry codes in a lot of places throughout the app, and it's unlikely to change between requests,
	// so we use a static variable here to cache the value to prevent fetching it more than once
	@observable private static _industryCodes = [] as IndustryCodesEntity[];
	@observable private static _promise = null as Promise<IndustryCodesEntity[]>|null;
	public static industryCodes(): Promise<IndustryCodesEntity[]> {
		if (IndustryCodesEntity._promise) {
			return IndustryCodesEntity._promise;
		}

		IndustryCodesEntity._promise = new Promise(resolve => {
			store.apolloClient.query({
				query: gql`
					query fetchIndustryCodes {
						industryCodesEntitys(
							orderBy: [{path: "code", descending: false}],
						) {
							id
							code
							description
						}
					}
				`,
				fetchPolicy: 'no-cache',
			}).then(({ data }) => {
				runInAction(() => {
					IndustryCodesEntity._industryCodes = data.industryCodesEntitys
						.map((attributes: IIndustryCodesEntityAttributes) => new IndustryCodesEntity(attributes))
						.sort((one: IndustryCodesEntity, two: IndustryCodesEntity) => {
							// Manually sort so that we can put the 'Unknown/Other' value on top
							if (
								!one.code
								|| (
									!!two.code
									&& one.code.toLowerCase() < two.code.toLowerCase()
								)) {
								return -1;
							}
							return 1;
						});

					resolve(IndustryCodesEntity._industryCodes);
				});
			});
		});

		return IndustryCodesEntity._promise;
	}

	// If we want to get the codes, but can't wait, then this will return the current value
	// It will still fetch the codes, but you won't see the results until a future call
	// If called from within an observer, though, it'll still rerender because this is a computed field
	@computed
	public static get industryCodesSync() {
		IndustryCodesEntity.industryCodes();
		return IndustryCodesEntity._industryCodes;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(IndustryCodesEntity.prototype, 'created');
CRUD(modifiedAttr)(IndustryCodesEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
