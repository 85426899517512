/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsAtoFile from 'Models/Security/Acl/VisitorsAtoFile';
import UserAtoFile from 'Models/Security/Acl/UserAtoFile';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IAtoFileEntityAttributes extends IModelAttributes {
	atoProcessingStatus: Enums.processingStatus;
	fileDate: Date;
	fileName: string;
	fileKey: string;

	atoFileRecordss: Array<
		| Models.AtoRecordEntity
		| Models.IAtoRecordEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AtoFileEntity', 'ATO File')
// % protected region % [Customise your entity metadata here] end
export default class AtoFileEntity extends Model
	implements IAtoFileEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsAtoFile(),
		new UserAtoFile(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'ATO Processing Status'] off begin
	@observable
	@attribute<AtoFileEntity, Enums.processingStatus>()
	@CRUD({
		name: 'ATO Processing Status',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.processingStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.processingStatusOptions),
		displayFunction: (attr: Enums.processingStatus) => Enums.processingStatusOptions[attr],
	})
	public atoProcessingStatus: Enums.processingStatus;
	// % protected region % [Modify props to the crud options here for attribute 'ATO Processing Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'File Date'] off begin
	/**
	 * Target Year and Month for the data. Day should always be 0.
	 */
	@observable
	@attribute<AtoFileEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'File Date',
		displayType: 'datepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public fileDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'File Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'File Name'] off begin
	@observable
	@attribute<AtoFileEntity, string>()
	@CRUD({
		name: 'File Name',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public fileName: string;
	// % protected region % [Modify props to the crud options here for attribute 'File Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'File Key'] off begin
	@observable
	@attribute<AtoFileEntity, string>()
	@CRUD({
		name: 'File Key',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public fileKey: string;
	// % protected region % [Modify props to the crud options here for attribute 'File Key'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ATO File Records'] off begin
		name: 'ATO File Recordss',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.AtoRecordEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'atoFileRecordss',
			oppositeEntity: () => Models.AtoRecordEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ATO File Records'] end
	})
	public atoFileRecordss: Models.AtoRecordEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAtoFileEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAtoFileEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.atoProcessingStatus !== undefined) {
				this.atoProcessingStatus = attributes.atoProcessingStatus;
			}
			if (attributes.fileDate !== undefined) {
				if (attributes.fileDate === null) {
					this.fileDate = attributes.fileDate;
				} else {
					this.fileDate = moment(attributes.fileDate).toDate();
				}
			}
			if (attributes.fileName !== undefined) {
				this.fileName = attributes.fileName;
			}
			if (attributes.fileKey !== undefined) {
				this.fileKey = attributes.fileKey;
			}
			if (attributes.atoFileRecordss !== undefined && Array.isArray(attributes.atoFileRecordss)) {
				for (const model of attributes.atoFileRecordss) {
					if (model instanceof Models.AtoRecordEntity) {
						this.atoFileRecordss.push(model);
					} else {
						this.atoFileRecordss.push(new Models.AtoRecordEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		atoFileRecordss {
			${Models.AtoRecordEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			atoFileRecordss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AtoFileEntity.prototype, 'created');
CRUD(modifiedAttr)(AtoFileEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
