/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserDiscountEntity from 'Models/Security/Acl/UserDiscountEntity';
import VisitorsDiscountEntity from 'Models/Security/Acl/VisitorsDiscountEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import OrganisationEntity from './OrganisationEntity';
import { store } from 'Models/Store';
import gql from 'graphql-tag';
import { buildSearchConditions } from 'Util/GraphQLUtils';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
// % protected region % [Add any further imports here] end

export interface IDiscountEntityAttributes extends IModelAttributes {
	source: Enums.discountSource;
	expirationDate: Date;
	discountPercent: number;

	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('DiscountEntity', 'Discount')
// % protected region % [Customise your entity metadata here] end
export default class DiscountEntity extends Model
	implements IDiscountEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserDiscountEntity(),
		new VisitorsDiscountEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Source'] off begin
	/**
	 * The source of the discount
	 */
	@observable
	@attribute<DiscountEntity, Enums.discountSource>()
	@CRUD({
		name: 'Source',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.discountSourceOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.discountSourceOptions),
		displayFunction: (attr: Enums.discountSource) => Enums.discountSourceOptions[attr],
	})
	public source: Enums.discountSource;
	// % protected region % [Modify props to the crud options here for attribute 'Source'] end

	// % protected region % [Modify props to the crud options here for attribute 'Expiration Date'] off begin
	/**
	 * Discount apply from the moment they are created until their expiration date
	 */
	@observable
	@attribute<DiscountEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Expiration Date',
		displayType: 'datepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public expirationDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Expiration Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Discount Percent'] off begin
	@Validators.Integer()
	@observable
	@attribute<DiscountEntity, number>()
	@CRUD({
		name: 'Discount Percent',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public discountPercent: number;
	// % protected region % [Modify props to the crud options here for attribute 'Discount Percent'] end

	/**
	 * An organisation can have discounts
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] on begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: attr => {
			return {
				query: `%${attr}%`,
				extraOptions: {
					case: 'INVARIANT_CULTURE_IGNORE_CASE',
					path: 'organisation.primaryBusinessEntity.name',
				},
			};
		},
		referenceTypeFunc: () => Models.OrganisationEntity,
		referenceResolveFunction: async (search, options) => {
			const searchConditions: IWhereCondition<string>[][] = [];
			if (!!search && typeof search === 'string') {
				searchConditions.push(
					buildSearchConditions(['primaryBusinessEntity.name'], search, 'INVARIANT_CULTURE_IGNORE_CASE'),
				);
			}
			const orderBy = [{ path: 'primaryBusinessEntity.name', descending: false }];
			const { data } = await store.apolloClient.query({
				query: gql`
				query organisationEntitys(
					$orderBy: [OrderByGraph],
					$conditions: [[WhereExpressionGraph]]
				) {
					organisationEntitys(conditions: $conditions, orderBy: $orderBy) {
						id
						primaryBusinessEntity {
							id
							name
						}
					}
				}
				`,
				variables: {
					orderBy: orderBy,
					conditions: searchConditions,
				},
				fetchPolicy: 'network-only',
			});

			return data.organisationEntitys
				.map((org: any) => new OrganisationEntity(org))
				.map((org: OrganisationEntity) => ({ display: org.primaryBusinessEntity?.name, value: org }));
		},
		optionEqualFunc: (modelProperty, option) => {
			return modelProperty.id === option;
		},
		// @ts-ignore
		displayFunction: (attr, that) => that.organisation?.primaryBusinessEntity?.name,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IDiscountEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IDiscountEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.source !== undefined) {
				this.source = attributes.source;
			}
			if (attributes.expirationDate !== undefined) {
				if (attributes.expirationDate === null) {
					this.expirationDate = attributes.expirationDate;
				} else {
					this.expirationDate = moment(attributes.expirationDate).toDate();
				}
			}
			if (attributes.discountPercent !== undefined) {
				this.discountPercent = attributes.discountPercent;
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
			${Models.OrganisationEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return `${this.discountPercent}% (${this.source})`;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public listExpands = `
		organisation {
			id
			primaryBusinessEntity {
				id
				name
			}
		}
	`;
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(DiscountEntity.prototype, 'created');
CRUD(modifiedAttr)(DiscountEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
