/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] on begin
import { LocaleCompareObjectAsc } from 'Util/StringUtils';
// % protected region % [Add any extra imports here] end

export type atbFileType =
	// % protected region % [Override atbFileType keys here] on begin
	'XERO'|
	'STANDARD' |
	'UNKNOWN' |
	'CUSTOMERS_ONLY';
	// % protected region % [Override atbFileType keys here] end

export const atbFileTypeOptions: { [key in atbFileType]: string } = {
	// % protected region % [Override atbFileType display fields here] on begin
	XERO: 'Xero',
	STANDARD: 'Standard',
	UNKNOWN: 'Unknown',
	CUSTOMERS_ONLY: 'Customers Only',
	// % protected region % [Override atbFileType display fields here] end
};

export type atbJobStatus =
	// % protected region % [Override atbJobStatus keys here] on begin
	'CREATED'|
	'PROCESSING' |
	'PROCESSED' |
	'QUEUED';
	// % protected region % [Override atbJobStatus keys here] end

export const atbJobStatusOptions: { [key in atbJobStatus]: string } = {
	// % protected region % [Override atbJobStatus display fields here] on begin
	CREATED: 'Created',
	PROCESSING: 'Processing',
	PROCESSED: 'Processed',
	QUEUED: 'Queued',
	// % protected region % [Override atbJobStatus display fields here] end
};

export type atbProcessingStatus =
	// % protected region % [Override atbProcessingStatus keys here] off begin
	'PROCESSING' |
	'CREATED' |
	'PROCESSED';
	// % protected region % [Override atbProcessingStatus keys here] end

export const atbProcessingStatusOptions: { [key in atbProcessingStatus]: string } = {
	// % protected region % [Override atbProcessingStatus display fields here] off begin
	PROCESSING: 'Processing',
	CREATED: 'Created',
	PROCESSED: 'Processed',
	// % protected region % [Override atbProcessingStatus display fields here] end
};

export type atoEntityType =
	// % protected region % [Override atoEntityType keys here] off begin
	'C' |
	'P' |
	'T' |
	'I';
	// % protected region % [Override atoEntityType keys here] end

export const atoEntityTypeOptions: { [key in atoEntityType]: string } = {
	// % protected region % [Override atoEntityType display fields here] off begin
	C: 'Company',
	P: 'Partnership',
	T: 'Trust',
	I: 'Individual',
	// % protected region % [Override atoEntityType display fields here] end
};

export type atoRecordStatus =
	// % protected region % [Override atoRecordStatus keys here] off begin
	'N' |
	'U' |
	'X' |
	'R' |
	'E';
	// % protected region % [Override atoRecordStatus keys here] end

export const atoRecordStatusOptions: { [key in atoRecordStatus]: string } = {
	// % protected region % [Override atoRecordStatus display fields here] on begin
	N: 'New',
	U: 'Updated',
	X: 'Existing',
	R: 'Removed',
	E: 'Error',
	// % protected region % [Override atoRecordStatus display fields here] end
};

export type accountType =
	// % protected region % [Override accountType keys here] off begin
	'INVOICED' |
	'PAYG';
	// % protected region % [Override accountType keys here] end

export const accountTypeOptions: { [key in accountType]: string } = {
	// % protected region % [Override accountType display fields here] off begin
	INVOICED: 'Invoiced',
	PAYG: 'PAYG',
	// % protected region % [Override accountType display fields here] end
};

export type alaresCategory =
	// % protected region % [Override alaresCategory keys here] off begin
	'LICENCE' |
	'ASIC_DOCUMENT' |
	'INSOLVENCY' |
	'ADJUDICATION' |
	'ALARES_CASE';
	// % protected region % [Override alaresCategory keys here] end

export const alaresCategoryOptions: { [key in alaresCategory]: string } = {
	// % protected region % [Override alaresCategory display fields here] on begin
	ADJUDICATION: 'Adjudication',
	ALARES_CASE: 'Case',
	ASIC_DOCUMENT: 'Asic',
	INSOLVENCY: 'Insolvency',
	LICENCE: 'Licence',
	// % protected region % [Override alaresCategory display fields here] end
};

export type auditCategory =
	// % protected region % [Override auditCategory keys here] off begin
	'CREDIT_DEFAULT' |
	'ADVERSE_ALERT' |
	'RISK_SCORE' |
	'ENTITY_UPDATE' |
	'OTHER';
	// % protected region % [Override auditCategory keys here] end

export const auditCategoryOptions: { [key in auditCategory]: string } = {
	// % protected region % [Override auditCategory display fields here] off begin
	CREDIT_DEFAULT: 'Credit Default',
	ADVERSE_ALERT: 'Adverse Alert',
	RISK_SCORE: 'Risk Score',
	ENTITY_UPDATE: 'Entity Update',
	OTHER: 'Other',
	// % protected region % [Override auditCategory display fields here] end
};

export type creditReportStatus =
	// % protected region % [Override creditReportStatus keys here] off begin
	'REQUESTED' |
	'GENERATED' |
	'FAILED' |
	'COMPLETED' |
	'ERROR';
	// % protected region % [Override creditReportStatus keys here] end

export const creditReportStatusOptions: { [key in creditReportStatus]: string } = {
	// % protected region % [Override creditReportStatus display fields here] off begin
	REQUESTED: 'Requested',
	GENERATED: 'Generated',
	FAILED: 'Failed',
	COMPLETED: 'Completed',
	ERROR: 'Error',
	// % protected region % [Override creditReportStatus display fields here] end
};

export type customerAuditCategory =
	// % protected region % [Override customerAuditCategory keys here] off begin
	'ACCOUNT_INFORMATION' |
	'ATO_DEFAULT' |
	'ADVERSE_ALERT' |
	'BUSINESS_INFORMATION' |
	'COMMENT' |
	'RISK_SCORE' |
	'REFERRED_FOR_COLLECTION' |
	'OTHER';
	// % protected region % [Override customerAuditCategory keys here] end

export const customerAuditCategoryOptions: { [key in customerAuditCategory]: string } = {
	// % protected region % [Override customerAuditCategory display fields here] off begin
	ACCOUNT_INFORMATION: 'Account Information',
	ATO_DEFAULT: 'ATO Default',
	ADVERSE_ALERT: 'Adverse Alert',
	BUSINESS_INFORMATION: 'Business Information',
	COMMENT: 'Comment',
	RISK_SCORE: 'Risk Score',
	REFERRED_FOR_COLLECTION: 'Referred for Collection',
	OTHER: 'Other',
	// % protected region % [Override customerAuditCategory display fields here] end
};

export type customerMonitoredStatus =
	// % protected region % [Override customerMonitoredStatus keys here] off begin
	'MONITORED' |
	'INSOLVENT' |
	'NOT_MONITORED' |
	'DELETED';
	// % protected region % [Override customerMonitoredStatus keys here] end

export const customerMonitoredStatusOptions: { [key in customerMonitoredStatus]: string } = {
	// % protected region % [Override customerMonitoredStatus display fields here] off begin
	MONITORED: 'Monitored',
	INSOLVENT: 'Insolvent',
	NOT_MONITORED: 'Not Monitored',
	DELETED: 'Deleted',
	// % protected region % [Override customerMonitoredStatus display fields here] end
};

export type discountSource =
	// % protected region % [Override discountSource keys here] off begin
	'CUSTOM' |
	'REFERRER' |
	'MIGRATION';
	// % protected region % [Override discountSource keys here] end

export const discountSourceOptions: { [key in discountSource]: string } = {
	// % protected region % [Override discountSource display fields here] off begin
	CUSTOM: 'Custom',
	REFERRER: 'Referrer',
	MIGRATION: 'Migration',
	// % protected region % [Override discountSource display fields here] end
};

export type esignceremonystatus =
	// % protected region % [Override esignceremonystatus keys here] off begin
	'CREATED' |
	'INCOMPLETE' |
	'COMPLETED' |
	'AWAITINGWITNESS' |
	'REJECTED' |
	'SIGNED' |
	'VISITED' |
	'SENT';
	// % protected region % [Override esignceremonystatus keys here] end

export const esignceremonystatusOptions: { [key in esignceremonystatus]: string } = {
	// % protected region % [Override esignceremonystatus display fields here] on begin
	CREATED: 'Created',
	INCOMPLETE: 'Incomplete',
	COMPLETED: 'Completed',
	AWAITINGWITNESS: 'Awaiting Witness',
	REJECTED: 'Rejected',
	SIGNED: 'Signed',
	VISITED: 'Visited',
	SENT: 'Sent',
	// % protected region % [Override esignceremonystatus display fields here] end
};

export type esignuserstatus =
	// % protected region % [Override esignuserstatus keys here] off begin
	'ACTIVE' |
	'INACTIVE';
	// % protected region % [Override esignuserstatus keys here] end

export const esignuserstatusOptions: { [key in esignuserstatus]: string } = {
	// % protected region % [Override esignuserstatus display fields here] off begin
	ACTIVE: 'Active',
	INACTIVE: 'Inactive',
	// % protected region % [Override esignuserstatus display fields here] end
};

export type esignwitnessstatus =
	// % protected region % [Override esignwitnessstatus keys here] off begin
	'NOT_STARTED' |
	'SENT' |
	'VISITED' |
	'SIGNED' |
	'REJECTED';
	// % protected region % [Override esignwitnessstatus keys here] end

export const esignwitnessstatusOptions: { [key in esignwitnessstatus]: string } = {
	// % protected region % [Override esignwitnessstatus display fields here] off begin
	NOT_STARTED: 'Not Started',
	SENT: 'Sent',
	VISITED: 'Visited',
	SIGNED: 'Signed',
	REJECTED: 'Rejected',
	// % protected region % [Override esignwitnessstatus display fields here] end
};

export type esignworkflowstage =
	// % protected region % [Override esignworkflowstage keys here] off begin
	'INITIATED' |
	'CEREMONYCREATED' |
	'SIGNATORYEMAILSENT' |
	'INPROGESS' |
	'COMPLETED';
	// % protected region % [Override esignworkflowstage keys here] end

export const esignworkflowstageOptions: { [key in esignworkflowstage]: string } = {
	// % protected region % [Override esignworkflowstage display fields here] on begin
	INITIATED: 'Initiated',
	CEREMONYCREATED: 'Ceremony Created',
	SIGNATORYEMAILSENT: 'Signatory Email Sent',
	INPROGESS: 'In Progess',
	COMPLETED: 'Completed',
	// % protected region % [Override esignworkflowstage display fields here] end
};

export type esignworkflowstatus =
	// % protected region % [Override esignworkflowstatus keys here] off begin
	'CREATED' |
	'PROCESSING' |
	'COMPLETED' |
	'INCOMPLETE' |
	'CANCELED' |
	'REJECTED';
	// % protected region % [Override esignworkflowstatus keys here] end

export const esignworkflowstatusOptions: { [key in esignworkflowstatus]: string } = {
	// % protected region % [Override esignworkflowstatus display fields here] off begin
	CREATED: 'Created',
	PROCESSING: 'Processing',
	COMPLETED: 'Completed',
	INCOMPLETE: 'Incomplete',
	CANCELED: 'Canceled',
	REJECTED: 'Rejected',
	// % protected region % [Override esignworkflowstatus display fields here] end
};

export type processingStatus =
	// % protected region % [Override processingStatus keys here] off begin
	'PROCESSING' |
	'PROCESSED' |
	'CREATED' |
	'ERROR';
	// % protected region % [Override processingStatus keys here] end

export const processingStatusOptions: { [key in processingStatus]: string } = {
	// % protected region % [Override processingStatus display fields here] off begin
	PROCESSING: 'Processing',
	PROCESSED: 'Processed',
	CREATED: 'Created',
	ERROR: 'Error',
	// % protected region % [Override processingStatus display fields here] end
};

export type referrerBenefitType =
	// % protected region % [Override referrerBenefitType keys here] off begin
	'CHARITY' |
	'CLIENT_DISCOUNT' |
	'NO_COMMISSION' |
	'COMMISSION';
	// % protected region % [Override referrerBenefitType keys here] end

export const referrerBenefitTypeOptions: { [key in referrerBenefitType]: string } = {
	// % protected region % [Override referrerBenefitType display fields here] off begin
	CHARITY: 'Charity',
	CLIENT_DISCOUNT: 'Client Discount',
	NO_COMMISSION: 'No Commission',
	COMMISSION: 'Commission',
	// % protected region % [Override referrerBenefitType display fields here] end
};

export type referrerBusinessType =
	// % protected region % [Override referrerBusinessType keys here] off begin
	'LAWYER' |
	'INSOLVENCY_PRACTITIONER' |
	'DEBT_COLLECTION' |
	'CREDIT_BUREAU' |
	'CREDIT_INSURANCE_BROKER' |
	'INDIVIDUAL' |
	'OTHER';
	// % protected region % [Override referrerBusinessType keys here] end

export const referrerBusinessTypeOptions: { [key in referrerBusinessType]: string } = {
	// % protected region % [Override referrerBusinessType display fields here] off begin
	LAWYER: 'Lawyer',
	INSOLVENCY_PRACTITIONER: 'Insolvency Practitioner',
	DEBT_COLLECTION: 'Debt Collection',
	CREDIT_BUREAU: 'Credit Bureau',
	CREDIT_INSURANCE_BROKER: 'Credit Insurance Broker',
	INDIVIDUAL: 'Individual',
	OTHER: 'Other',
	// % protected region % [Override referrerBusinessType display fields here] end
};

export type referrerCommissionFrequency =
	// % protected region % [Override referrerCommissionFrequency keys here] off begin
	'SIX_MONTHLY' |
	'YEARLY' |
	'QUARTERLY';
	// % protected region % [Override referrerCommissionFrequency keys here] end

export const referrerCommissionFrequencyOptions: { [key in referrerCommissionFrequency]: string } = {
	// % protected region % [Override referrerCommissionFrequency display fields here] off begin
	SIX_MONTHLY: 'Six Monthly',
	YEARLY: 'Yearly',
	QUARTERLY: 'Quarterly',
	// % protected region % [Override referrerCommissionFrequency display fields here] end
};

export type registrationStatus =
	// % protected region % [Override registrationStatus keys here] off begin
	'CREATED' |
	'INCOMPLETE' |
	'COMPLETE' |
	'PENDING';
	// % protected region % [Override registrationStatus keys here] end

export const registrationStatusOptions: { [key in registrationStatus]: string } = {
	// % protected region % [Override registrationStatus display fields here] off begin
	CREATED: 'Created',
	INCOMPLETE: 'Incomplete',
	COMPLETE: 'Complete',
	PENDING: 'Pending',
	// % protected region % [Override registrationStatus display fields here] end
};

export type riskScore =
	// % protected region % [Override riskScore keys here] off begin
	'UNKNOWN' |
	'MINIMAL' |
	'VERY_LOW' |
	'LOW' |
	'AVERAGE' |
	'MODERATE' |
	'HIGH' |
	'VERY_HIGH' |
	'SEVERE';
	// % protected region % [Override riskScore keys here] end

export const riskScoreOptions: { [key in riskScore]: string } = {
	// % protected region % [Override riskScore display fields here] on begin
	UNKNOWN: 'Unknown',
	MINIMAL: 'Minimal',
	VERY_LOW: 'Very Low',
	LOW: 'Low',
	AVERAGE: 'Average',
	MODERATE: 'Moderate',
	HIGH: 'High',
	VERY_HIGH: 'Very High',
	SEVERE: 'Severe',
	// % protected region % [Override riskScore display fields here] end
};

export type state =
	// % protected region % [Override state keys here] off begin
	'NONE' |
	'ACT' |
	'NSW' |
	'NT' |
	'QLD' |
	'SA' |
	'TAS' |
	'VIC' |
	'WA';
	// % protected region % [Override state keys here] end

export const stateOptions: { [key in state]: string } = {
	// % protected region % [Override state display fields here] off begin
	NONE: 'None',
	ACT: 'ACT',
	NSW: 'NSW',
	NT: 'NT',
	QLD: 'QLD',
	SA: 'SA',
	TAS: 'TAS',
	VIC: 'VIC',
	WA: 'WA',
	// % protected region % [Override state display fields here] end
};

export type userType =
	// % protected region % [Override userType keys here] off begin
	'REFERRAL_PARTNER' |
	'SALESPERSON' |
	'RESTRICTED_USER' |
	'USER' |
	'ADMIN' |
	'ORGANISATION_MANAGER' |
	'SUPER_USER';
	// % protected region % [Override userType keys here] end

export const userTypeOptions: { [key in userType]: string } = {
	// % protected region % [Override userType display fields here] off begin
	REFERRAL_PARTNER: 'Referral Partner',
	SALESPERSON: 'Salesperson',
	RESTRICTED_USER: 'Restricted User',
	USER: 'User',
	ADMIN: 'Admin',
	ORGANISATION_MANAGER: 'Organisation Manager',
	SUPER_USER: 'Super User',
	// % protected region % [Override userType display fields here] end
};

// % protected region % [Add any extra enums here] on begin
/* eslint-disable import/first */
import { ComboboxOption } from 'Views/Components/Combobox/Combobox';
import { store } from 'Models/Store';

export const UserTypeOrder: { [key in userType]: number } = {
	RESTRICTED_USER: 0,
	SALESPERSON: 1,
	USER: 2,
	REFERRAL_PARTNER: 3,
	ADMIN: 4,
	ORGANISATION_MANAGER: 5,
	SUPER_USER: 6,
};

/**
 * Function to determine the available userTypes for a given usertype
 * @param userType The userType to limit the options to
 * @returns An array of the userTypes available to the given userType
 */
const availableUserTypes = (userType: userType) : string[] => {
	const types: string[] = [];
	Object.keys(UserTypeOrder).forEach(type => {
		if (UserTypeOrder[userType] >= UserTypeOrder[type]) {
			types.push(type);
		}
	});
	return types;
};

// Return the options for a combobox for the usertype enum
export const reducedUserTypeComboboxOptions = (userType?: userType, disabled?: boolean) : ComboboxOption<string>[] => {
	let values = Object.keys(userTypeOptions);

	// if a usertype is given and the combobox is not disabled then reduce list of available usertypes
	if (userType && !disabled) {
		values = availableUserTypes(userType);
	}

	// Remove referral partner as of right now, but in future TODO: add back
	values.splice(values.indexOf('REFERRAL_PARTNER'), 1);

	return values
		.sort(LocaleCompareObjectAsc(userTypeOptions))
		.map<ComboboxOption<string>>((value, index, array) => {
			return { display: userTypeOptions[value], value: value };
		});
};

// The enum describing what scope of userEntitys the permission belongs to
export type userPermissionScope =
	'SELF' |
		'WITHIN_BUSINESS_ENTITIES' |
		'WITHIN_ORGANISATION' |
		'ALL';

export const userPermissionScopeOptions: { [key in userPermissionScope]: string } = {
	SELF: 'Self',
	WITHIN_BUSINESS_ENTITIES: 'Within Business Entities',
	WITHIN_ORGANISATION: 'Within Organisation',
	ALL: 'All',
};

export const userPermissionScopeOrder: { [key in userPermissionScope]: number } = {
	SELF: 0,
	WITHIN_BUSINESS_ENTITIES: 1,
	WITHIN_ORGANISATION: 2,
	ALL: 3,
};

// Permission scope for organisations
export type organisationPermissionScope =
	'NONE' |
	'WITHIN_ORGANISATION' |
	'ALL';

export const organisationPermissionScopeOptions: { [key in organisationPermissionScope]: string } = {
	NONE: 'None',
	WITHIN_ORGANISATION: 'Within Organisation',
	ALL: 'All',
};

export const organisationPermissionScopeOrder: { [key in organisationPermissionScope]: number } = {
	NONE: 0,
	WITHIN_ORGANISATION: 1,
	ALL: 2,
};

// Permission scope for business entities
export type businessEntitiesPermissionScope =
	'NONE' |
	'WITHIN_ORGANISATION' |
	'WITHIN_BUSINESS_ENTITIES' |
	'ALL';

export const businessEntitiesPermissionScopeOptions: { [key in businessEntitiesPermissionScope]: string } = {
	NONE: 'None',
	WITHIN_BUSINESS_ENTITIES: 'Within Business Entity',
	WITHIN_ORGANISATION: 'Within Organisation',
	ALL: 'All',
};

export const businessEntitiesPermissionScopeOrder: { [key in businessEntitiesPermissionScope]: number } = {
	NONE: 0,
	WITHIN_BUSINESS_ENTITIES: 1,
	WITHIN_ORGANISATION: 2,
	ALL: 3,
};

/**
 * Function to return a list of the available scope options given the current scope option.
 * @param userScope The given scope of the permission
 * @param currentScope The current user's permission scope
 * @param permissionsScopeOrder A mapping for the scope enum values to an int value
 * @returns An array of available scope options given a specific permission scope
 */
const availablePermissionScopeOptions = (
	userScope: string,
	currentScope: string,
	permissionsScopeOrder: { [key in string]: number },
): string[] => {
	const scopeOptions: string[] = [];

	Object.keys(permissionsScopeOrder).forEach(option => {
		if (permissionsScopeOrder[userScope] >= permissionsScopeOrder[option]
			&& permissionsScopeOrder[currentScope] >= permissionsScopeOrder[option]) {
			scopeOptions.push(option);
		}
	});

	return scopeOptions;
};

// Return the options for a combobox for the userPermissionScope
export const permissionScopeCombobox = (
	type: 'user'|'organisation'|'businessEntity',
	permission?: userPermissionScope,
	currentPermission?: userPermissionScope,
	disabled?: boolean,
) : ComboboxOption<string>[] => {
	let permissionScopeOptions: { [key in string]: string };
	let permissionScopeOrder: { [key in string]: number };
	switch (type) {
		case 'user':
			permissionScopeOptions = userPermissionScopeOptions;
			permissionScopeOrder = userPermissionScopeOrder;
			break;
		case 'organisation':
			permissionScopeOptions = organisationPermissionScopeOptions;
			permissionScopeOrder = organisationPermissionScopeOrder;
			break;
		case 'businessEntity':
			permissionScopeOptions = businessEntitiesPermissionScopeOptions;
			permissionScopeOrder = businessEntitiesPermissionScopeOrder;
			break;
	}

	let values = Object.keys(permissionScopeOptions);

	// given that the current permission scope is given and combobox is not disabled, reduce list
	if (permission && currentPermission && !disabled) {
		values = availablePermissionScopeOptions(permission, currentPermission, permissionScopeOrder);
	}

	return values
		.sort(LocaleCompareObjectAsc(permissionScopeOptions))
		.map<ComboboxOption<string>>((value, index, array) => {
			return { display: permissionScopeOptions[value], value: value };
		});
};

export const UserTypeComboBoxOptions = () : ComboboxOption<string>[] => {
	const values = Object.keys(userTypeOptions);

	// Remove referral partner as of right now, but in future TODO: add back
	values.splice(values.indexOf('REFERRAL_PARTNER'), 1);

	// If you're not a super user, you don't see the super user option
	if (store.userType !== 'SUPER_USER') {
		values.splice(values.indexOf('SUPER_USER'), 1);
	}

	return values
		.sort(LocaleCompareObjectAsc(userTypeOptions))
		.map<ComboboxOption<string>>((value, index, array) => {
			return { display: userTypeOptions[value], value: value };
		});
};

// Options in alphabetic order
export const registrationComboboxOptions: ComboboxOption<registrationStatus | 'ALL'>[] = [
	{ display: 'All Statuses', value: 'ALL' },
	{ display: 'Complete', value: 'COMPLETE' },
	{ display: 'Incomplete', value: 'INCOMPLETE' },
	{ display: 'Pending', value: 'PENDING' },
];

export const registrationWithCreatedComboboxOptions: ComboboxOption<registrationStatus | 'ALL'>[] = [
	{ display: 'All Statuses', value: 'ALL' },
	{ display: 'Complete', value: 'COMPLETE' },
	{ display: 'Created', value: 'CREATED' },
	{ display: 'Incomplete', value: 'INCOMPLETE' },
	{ display: 'Pending', value: 'PENDING' },
];

export const riskScoreComboboxOptions: ComboboxOption<riskScore | 'ALL'>[] = [
	{ display: 'All Risk Ratings', value: 'ALL' },
	{ display: 'Unknown', value: 'UNKNOWN' },
	{ display: 'Minimal', value: 'MINIMAL' },
	{ display: 'Very Low', value: 'VERY_LOW' },
	{ display: 'Low', value: 'LOW' },
	{ display: 'Average', value: 'AVERAGE' },
	{ display: 'Moderate', value: 'MODERATE' },
	{ display: 'High', value: 'HIGH' },
	{ display: 'Very High', value: 'VERY_HIGH' },
	{ display: 'Severe', value: 'SEVERE' },
];

export const customerMonitoredStatusComboboxOptions: ComboboxOption<customerMonitoredStatus | 'ALL'>[] = [
	{ display: 'All Monitored Statuses', value: 'ALL' },
	{ display: 'Monitored', value: 'MONITORED' },
	{ display: 'Insolvent', value: 'INSOLVENT' },
	{ display: 'Not Monitored', value: 'NOT_MONITORED' },
	{ display: 'Deleted', value: 'DELETED' },
];

// Options in alphabetic order
export type ausStates =
	'ALL' |
	'ACT' |
	'NSW' |
	'NT' |
	'QLD' |
	'SA' |
	'TAS' |
	'VIC' |
	'WA';

// Options in alphabetic order
export const ausStateOptions: { [key in ausStates]: string } = {
	ALL: 'All states',
	ACT: 'Australian Capital Territory',
	NSW: 'New South Wales',
	NT: 'Northern Territory',
	QLD: 'Queensland',
	SA: 'South Australia',
	TAS: 'Tasmania',
	VIC: 'Victoria',
	WA: 'Western Australia',
};

export const referrerBusinessTypeComboboxOptions: ComboboxOption<referrerBusinessType | 'ALL'>[] = [
	{ display: 'All Business Types', value: 'ALL' },
	{ display: 'Lawyer', value: 'LAWYER' },
	{ display: 'Debt Collection', value: 'DEBT_COLLECTION' },
	{ display: 'Insolvency Practitioner', value: 'INSOLVENCY_PRACTITIONER' },
	{ display: 'Credit Insurance Broker', value: 'CREDIT_INSURANCE_BROKER' },
	{ display: 'Credit Bureau', value: 'CREDIT_BUREAU' },
	{ display: 'Individual', value: 'INDIVIDUAL' },
	{ display: 'Other', value: 'OTHER' },
];

export const referrerBenefitTypeComboboxOptions: ComboboxOption<referrerBenefitType | 'ALL'>[] = [
	{ display: 'All Benefit Types', value: 'ALL' },
	{ display: 'Commission', value: 'COMMISSION' },
	{ display: 'Charity', value: 'CHARITY' },
	{ display: 'Client Discount', value: 'CLIENT_DISCOUNT' },
	{ display: 'No Commission', value: 'NO_COMMISSION' },
];

export const ausStateComboboxOptions: ComboboxOption<ausStates | 'ALL'>[] = (
	(Object.keys(ausStateOptions) as [ausStates | 'ALL'])
		.map<ComboboxOption<ausStates | 'ALL'>>((value, index, array) => {
			return {
				display: ausStateOptions[value],
				value: value,
			};
		})
);

export const acnTypeOptions: { [key: string]: string } = {
	APTY: 'Australian Proprietary Company',
	APUB: 'Australian Public Company',
	ASSN: 'Association',
	BUSN: 'Business name',
	CHAR: 'Charity',
	COMP: 'Community purpose',
	COOP: 'Co-operative society',
	FNOS: 'Foreign company (overseas)',
	LTDP: 'Limited partnership',
	MISM: 'Managed investment scheme',
	NONC: 'Non company',
	NRET: 'Non registered entity',
	RACN: 'Registered Australian Corporation',
	REBD: 'Religious body',
	RSVN: 'Name reservation',
	SOLS: 'Solicitor corporation',
	TRST: 'Trust',
};

export const acnStatusOptions: { [key: string]: string } = {
	APPR: 'Approved (trust)',
	ARCH: 'Business names - archived',
	ASOS: 'Association strike off status',
	CNCL: 'Cancelled',
	CONV: 'Converted (trust)',
	DISS: 'Dissolved by Special Act of Parliament',
	DIV3: 'Organisation Transferred Registration via DIV3',
	DMNT: 'Dormant',
	DRGD: 'De-registered',
	EXAA: 'External administration - associations',
	EXAD: 'External Administration',
	NOAC: 'Not Active',
	NRGD: 'Not Registered',
	PEND: 'Pending - schemes',
	PROV: 'Provisional',
	REGD: 'Registered',
	REXP: 'Business name expired',
	RMVD: 'Business name removed',
	SOFF: 'Strike-off Action in Progress',
	WDUP: 'Winding up - managed investments scheme',
	WDPI: 'Winding up - prescribed interest schemes',
	'NO RECORD': 'No records found',
	invalid: 'Invalid ACN',
};

export const acnClassOptions: { [key: string]: string } = {
	EQUT: 'Equity',
	LMGT: 'Limited by guarantee',
	LMSG: 'Limited by shares and guarantee',
	LMSH: 'Limited by shares',
	MORT: 'Mortgage',
	NLIA: 'No liability',
	NONE: 'Liability unknown',
	PROP: 'Property',
	UNKN: 'Liability unknown',
	UNLM: 'Unlimited',
};

export const acnSubClassOptions: { [key: string]: string } = {
	EXPN: 'Exempt proprietary company - non profit company',
	EXPS: 'Exempt proprietary and superannuation trustee company',
	EXPT: 'Exempt proprietary company',
	FXPT: 'Foreign company exempt from lodging balance sheet',
	HUNT: 'Proprietary - home unit company',
	LISN: 'Company licensed under Section 383 to omit \'Limited\' from its name',
	LISS: 'Company licensed under Section 383 to omit \'Limited\' from its name - superannuation trustee company',
	LIST: 'Listed public company',
	LXPT: 'Company licensed to omit \'Limited\' and exempt from returns',
	NEXN: 'Non-exempt proprietary company - non profit',
	NEXS: 'Non-exempt proprietary and superannuation trustee company',
	NEXT: 'Non-exempt proprietary company',
	NONE: 'Unknown',
	NLTD: 'Non-profit public company registered without \'Limited\' in its name under Section 150',
	PNPC: 'Proprietary - non-profit company',
	PSTC: 'Proprietary - superannuation trustee company',
	PUBF: 'Foreign company required to lodge a balance sheet',
	PROP: 'Proprietary - other',
	RACA: 'Registrable Australian corporation - association',
	RACO: 'Registrable Australian corporation - non association',
	SPEC: 'Special',
	ULSN: 'Unlisted public - non-profit company',
	ULSS: 'Unlisted public - superannuation trustee company',
	ULST: 'Unlisted public company',
};

// Options in alphabetic order
export const referrerBusinessTypeRegistrationComboboxOptions: ComboboxOption<string>[] = [
	{ display: 'Credit bureau', value: 'CREDIT_BUREAU' },
	{ display: 'Credit insurance broker', value: 'CREDIT_INSURANCE_BROKER' },
	{ display: 'Debt collection', value: 'DEBT_COLLECTION' },
	{ display: 'I am an individual', value: 'INDIVIDUAL' },
	{ display: 'Insolvency practitioner', value: 'INSOLVENCY_PRACTITIONER' },
	{ display: 'Lawyer', value: 'LAWYER' },
	{ display: 'Other (provide details)', value: 'OTHER' },
];

export const referrerBenefitTypeRegistrationComboboxOptions: ComboboxOption<string>[] = [
	{ display: 'Client to receive overall discount', value: 'CLIENT_DISCOUNT' },
	{ display: 'Do not receive commission', value: 'NO_COMMISSION' },
	{ display: 'Receive commission', value: 'COMMISSION' },
	{ display: 'Send commission to charity', value: 'CHARITY' },
];

export const referrerCommissionFrequencyComboboxOptions = () : ComboboxOption<string>[] => {
	const values = Object.keys(referrerCommissionFrequencyOptions);

	return values
		.sort(LocaleCompareObjectAsc(referrerCommissionFrequencyOptions))
		.map<ComboboxOption<string>>((value, index, array) => {
			return { display: referrerCommissionFrequencyOptions[value], value: value };
		});
};

export type ProcessingErrorType =
	'Internal'
	|'Invalid'
	|'Required'
	|'CustomerPartialMatch'
	|'CustomerNoMatch'
	|'CustomerDuplicateMatch';
export type ProcessingErrorInputType =
	'None'
	|'String'
	|'Integer'
	|'Currency'
	|'Abn'
	|'Acn'
	|'Date'
	|'Customer';

export const riskScoreEnumToValueRange: { [key: string]: string[] } = {
	MINIMAL: ['0', '5'],
	VERY_LOW: ['5', '10'],
	LOW: ['10', '15'],
	AVERAGE: ['15', '20'],
	MODERATE: ['20', '50'],
	HIGH: ['50', '70'],
	VERY_HIGH: ['70', '90'],
	SEVERE: ['90', '1000000000'],
};

export const paymentRiskToTooltip: { [key in riskScore]: string } = {
	UNKNOWN: 'Incorrect or insufficient information provided or available upon which to provide a score at this time. '
		+ 'Please refer to Insolvency Risk Score or make alternative enquiries.',
	MINIMAL: 'Credit assessment factors indicate this entity is highly likely to meet credit commitments in the next '
		+ '3 months. Recommendation - extend terms with usual caution to suit exposure.',
	VERY_LOW: 'Risk assessment factors indicate this entity is likely to meet credit commitments in the next 3 months. '
		+ 'Recommendation - extend terms with usual caution to suit exposure.',
	LOW: 'This entity has the ability to meet its credit commitments as they become payable. Recommendation is to '
		+ 'extend credit terms with caution and monitor for deterioration in capacity. ',
	AVERAGE: 'This entity has adequate ability to pay its debts as they fall due. Recommendation is to extend credit '
		+ 'terms but monitor for capacity impairment.  PPSR is always recommended where appropriate.',
	MODERATE: 'Indications are that this entity is stable but vulnerable.  Its ability to meet credit commitments is '
		+ 'dependent upon favourable conditions continuing. Recommendation is to extend credit terms cautiously with '
		+ 'close monitoring and PPSR in place.',
	HIGH: 'Risk assessment indicators show that this entity is under high pressure to make creditor payments as they '
		+ 'fall due. Unfavourable conditions going forward may further exacerbate this. Recommend PPSR and only extend '
		+ 'terms with extreme caution with monitoring in place for deteriorating payment behaviour essential.',
	VERY_HIGH: 'Risk indications are this entity is unlikely to be able to meet its credit commitments in the coming '
		+ 'months. Cash on Deliver trading and solid PPSR compliance is recommended.',
	SEVERE: 'Risk alert indications are this entity is in severe distress and unlikely to be able to meet its credit '
		+ 'commitments in the coming months. Cash on Deliver trading and solid PPSR compliance is recommended.',
};

export const insolvencyRiskToTooltip: { [key in riskScore]: string } = {
	UNKNOWN: 'Incorrect or insufficient information provided or available upon which to provide a score at this time. '
		+ 'Please refer to Payment Risk Score or make alternative enquiries.',
	MINIMAL: 'Credit assessment factors indicate this entity is highly likely to continue to remain  in business in the'
		+ ' next 12 months. Recommendation - extend terms with usual caution in line with exposure.',
	VERY_LOW: 'Risk assessment factors indicate this entity is likely to continue to remain  in business in the next 12'
		+ ' months. Recommendation - extend terms with usual caution in line with exposure.',
	LOW: 'This entity is stable and likely to remain in business in the coming 12 months.  Recommendation is to extend '
		+ 'credit terms with caution but monitor for stress indicators.',
	AVERAGE: 'This entity is fairly stable and likely to remain in business during the coming 12 months. Recommendation'
		+ ' is to extend credit terms but monitor for distress indicators. PPSR is always recommended where '
		+ 'appropriate.',
	MODERATE: 'Indications are that this entity is stable but vulnerable. Its ability to continue trading relies upon '
		+ 'favourable conditions. Recommendation is to extend credit terms cautiously with close monitoring and PPSR '
		+ 'in place.',
	HIGH: 'Risk assessment indicators show that this entity is trading precariously and in danger of failing within '
		+ 'the next 12 months. Unfavourable conditions going forward may lead to its early demise. Recommend PPSR '
		+ 'security is in place and only extend terms with extreme caution with monitoring in place for additional '
		+ 'insolvency indicators.',
	VERY_HIGH: 'Risk indicators show that the probability of this entity failing in the next 12 months is very high. '
		+ 'Cash on Delivery trading and solid PPSR compliance is recommended.',
	SEVERE: 'Risk alert indicators show that this entity is in severe distress and is highly likely to fail in the next'
		+ ' 12 months.  Cash on Delivery trading and solid PPSR compliance is recommended.',
};

export const customerAuditCategoryComboboxOptions: ComboboxOption<customerAuditCategory | auditCategory | 'ALL'>[] = [
	{ display: 'All Update Types', value: 'ALL' },
	{ display: 'Account Information', value: 'ACCOUNT_INFORMATION' },
	{ display: 'Adverse Alert', value: 'ADVERSE_ALERT' },
	{ display: 'Business Information', value: 'BUSINESS_INFORMATION' },
	{ display: 'Comment', value: 'COMMENT' },
	{ display: 'Credit Default', value: 'CREDIT_DEFAULT' },
	{ display: 'Entity Update', value: 'ENTITY_UPDATE' },
	{ display: 'Risk Score', value: 'RISK_SCORE' },
	{ display: 'Other', value: 'OTHER' },
];

export const atbFileTypeComboboxOptions: ComboboxOption<atbFileType | 'ALL'>[] = [
	{ display: 'All Types', value: 'ALL' },
	{ display: 'Xero', value: 'XERO'},
	{ display: 'Standard', value: 'STANDARD'},
	{ display: 'Unknown', value: 'UNKNOWN'},
	{ display: 'Customers Only', value: 'CUSTOMERS_ONLY'},
];
// % protected region % [Add any extra enums here] end
