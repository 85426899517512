/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import UserAuthorisedCreditBureauReferenceManyToMany from '../Security/Acl/UserAuthorisedCreditBureauReferenceManyToMany';
import VisitorsAuthorisedCreditBureauReferenceManyToMany from '../Security/Acl/VisitorsAuthorisedCreditBureauReferenceManyToMany';
// % protected region % [Add any further imports here] on begin
// Botwritten code is breaking this rule, and we can't really avoid it
/* eslint max-len: 0 */
// % protected region % [Add any further imports here] end

export interface IOrganisationAuthorisedCreditBureauAttributes extends IModelAttributes {
	organisationId: string;
	authorisedCreditBureauId: string;

	organisation: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	authorisedCreditBureau: Models.CreditBureauEntity | Models.ICreditBureauEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('OrganisationAuthorisedCreditBureau')
export default class OrganisationAuthorisedCreditBureau
	extends Model
	implements IOrganisationAuthorisedCreditBureauAttributes {
	public static acls: IAcl[] = [
		new UserAuthorisedCreditBureauReferenceManyToMany(),
		new VisitorsAuthorisedCreditBureauReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public organisationId: string;

	@observable
	@attribute()
	public authorisedCreditBureauId: string;

	@observable
	@attribute({ isReference: true })
	public organisation: Models.OrganisationEntity;

	@observable
	@attribute({ isReference: true })
	public authorisedCreditBureau: Models.CreditBureauEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IOrganisationAuthorisedCreditBureauAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.organisationId) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.authorisedCreditBureauId) {
				this.authorisedCreditBureauId = attributes.authorisedCreditBureauId;
			}

			if (attributes.organisation) {
				if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			} else if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}

			if (attributes.authorisedCreditBureau) {
				if (attributes.authorisedCreditBureau instanceof Models.CreditBureauEntity) {
					this.authorisedCreditBureau = attributes.authorisedCreditBureau;
					this.authorisedCreditBureauId = attributes.authorisedCreditBureau.id;
				} else {
					this.authorisedCreditBureau = new Models.CreditBureauEntity(attributes.authorisedCreditBureau);
					this.authorisedCreditBureauId = this.authorisedCreditBureau.id;
				}
			} else if (attributes.authorisedCreditBureauId !== undefined) {
				this.authorisedCreditBureauId = attributes.authorisedCreditBureauId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
