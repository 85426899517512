/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserActiveCustomerPricingTierEntity from 'Models/Security/Acl/UserActiveCustomerPricingTierEntity';
import VisitorsActiveCustomerPricingTierEntity from 'Models/Security/Acl/VisitorsActiveCustomerPricingTierEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { store } from 'Models/Store';
import { gql } from '@apollo/client';
import { computed, runInAction } from 'mobx';
// % protected region % [Add any further imports here] end

export interface IActiveCustomerPricingTierEntityAttributes extends IModelAttributes {
	maximumCustomers: number;
	approvePrice: number;
	intelPrice: number;
	ppsrPrice: number;

	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ActiveCustomerPricingTierEntity', 'Active Customer Pricing Tier')
// % protected region % [Customise your entity metadata here] end
export default class ActiveCustomerPricingTierEntity extends Model
	implements IActiveCustomerPricingTierEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserActiveCustomerPricingTierEntity(),
		new VisitorsActiveCustomerPricingTierEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Maximum Customers'] off begin
	/**
	 * Maximum Customers
	 */
	@Validators.Integer()
	@observable
	@attribute<ActiveCustomerPricingTierEntity, number>()
	@CRUD({
		name: 'Maximum Customers',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public maximumCustomers: number;
	// % protected region % [Modify props to the crud options here for attribute 'Maximum Customers'] end

	// % protected region % [Modify props to the crud options here for attribute 'Approve Price'] off begin
	/**
	 * Approve Price
	 */
	@Validators.Integer()
	@observable
	@attribute<ActiveCustomerPricingTierEntity, number>()
	@CRUD({
		name: 'Approve Price',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public approvePrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Approve Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Intel Price'] off begin
	/**
	 * Intel Price
	 */
	@Validators.Integer()
	@observable
	@attribute<ActiveCustomerPricingTierEntity, number>()
	@CRUD({
		name: 'Intel Price',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public intelPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Intel Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'PPSR Price'] off begin
	/**
	 * PPSR Price
	 */
	@Validators.Integer()
	@observable
	@attribute<ActiveCustomerPricingTierEntity, number>()
	@CRUD({
		name: 'PPSR Price',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public ppsrPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'PPSR Price'] end

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IActiveCustomerPricingTierEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IActiveCustomerPricingTierEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.maximumCustomers !== undefined) {
				this.maximumCustomers = attributes.maximumCustomers;
			}
			if (attributes.approvePrice !== undefined) {
				this.approvePrice = attributes.approvePrice;
			}
			if (attributes.intelPrice !== undefined) {
				this.intelPrice = attributes.intelPrice;
			}
			if (attributes.ppsrPrice !== undefined) {
				this.ppsrPrice = attributes.ppsrPrice;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ActiveCustomerPricingTierEntity.prototype, 'created');
CRUD(modifiedAttr)(ActiveCustomerPricingTierEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
