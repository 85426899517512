/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import UserUserReferenceManyToMany from '../Security/Acl/UserUserReferenceManyToMany';
import VisitorsUserReferenceManyToMany from '../Security/Acl/VisitorsUserReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IBusinessEntityUserAttributes extends IModelAttributes {
	businessEntityId: string;
	userId: string;

	businessEntity: Models.BusinessEntity | Models.IBusinessEntityAttributes;
	user: Models.UserEntity | Models.IUserEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('BusinessEntityUser')
export default class BusinessEntityUser
	extends Model
	implements IBusinessEntityUserAttributes {
	public static acls: IAcl[] = [
		new UserUserReferenceManyToMany(),
		new VisitorsUserReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public businessEntityId: string;

	@observable
	@attribute()
	public userId: string;

	@observable
	@attribute({ isReference: true })
	public businessEntity: Models.BusinessEntity;

	@observable
	@attribute({ isReference: true })
	public user: Models.UserEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IBusinessEntityUserAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.businessEntityId) {
				this.businessEntityId = attributes.businessEntityId;
			}
			if (attributes.userId) {
				this.userId = attributes.userId;
			}

			if (attributes.businessEntity) {
				if (attributes.businessEntity instanceof Models.BusinessEntity) {
					this.businessEntity = attributes.businessEntity;
					this.businessEntityId = attributes.businessEntity.id;
				} else {
					this.businessEntity = new Models.BusinessEntity(attributes.businessEntity);
					this.businessEntityId = this.businessEntity.id;
				}
			} else if (attributes.businessEntityId !== undefined) {
				this.businessEntityId = attributes.businessEntityId;
			}

			if (attributes.user) {
				if (attributes.user instanceof Models.UserEntity) {
					this.user = attributes.user;
					this.userId = attributes.user.id;
				} else {
					this.user = new Models.UserEntity(attributes.user);
					this.userId = this.user.id;
				}
			} else if (attributes.userId !== undefined) {
				this.userId = attributes.userId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
