/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsEsigntemplate from 'Models/Security/Acl/VisitorsEsigntemplate';
import UserEsigntemplate from 'Models/Security/Acl/UserEsigntemplate';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IEsigntemplateEntityAttributes extends IModelAttributes {
	name: string;
	lifespanindays: number;
	reminderfrequencyindays: number;
	minimumnumberofsignees: number;

	esigntemplatecontents: Array<
		| Models.EsigntemplatecontentEntity
		| Models.IEsigntemplatecontentEntityAttributes
	>;
	businessEntityId?: string;
	businessEntity?: Models.BusinessEntity | Models.IBusinessEntityAttributes;
	logoId?: string;
	logo?: Models.LogoEntity | Models.ILogoEntityAttributes;
	esignworkflowss: Array<
		| Models.EsignworkflowEntity
		| Models.IEsignworkflowEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('EsigntemplateEntity', 'ESignTemplate')
// % protected region % [Customise your entity metadata here] end
export default class EsigntemplateEntity extends Model
	implements IEsigntemplateEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsEsigntemplate(),
		new UserEsigntemplate(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<EsigntemplateEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'LifeSpanInDays'] off begin
	@Validators.Integer()
	@observable
	@attribute<EsigntemplateEntity, number>()
	@CRUD({
		name: 'LifeSpanInDays',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public lifespanindays: number;
	// % protected region % [Modify props to the crud options here for attribute 'LifeSpanInDays'] end

	// % protected region % [Modify props to the crud options here for attribute 'ReminderFrequencyInDays'] off begin
	@Validators.Integer()
	@observable
	@attribute<EsigntemplateEntity, number>()
	@CRUD({
		name: 'ReminderFrequencyInDays',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public reminderfrequencyindays: number;
	// % protected region % [Modify props to the crud options here for attribute 'ReminderFrequencyInDays'] end

	// % protected region % [Modify props to the crud options here for attribute 'MinimumNumberOfSignees'] off begin
	@Validators.Integer()
	@observable
	@attribute<EsigntemplateEntity, number>()
	@CRUD({
		name: 'MinimumNumberOfSignees',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public minimumnumberofsignees: number;
	// % protected region % [Modify props to the crud options here for attribute 'MinimumNumberOfSignees'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignTemplateContent'] off begin
		name: 'ESignTemplateContents',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.EsigntemplatecontentEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'esigntemplatecontents',
			oppositeEntity: () => Models.EsigntemplatecontentEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ESignTemplateContent'] end
	})
	public esigntemplatecontents: Models.EsigntemplatecontentEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] off begin
		name: 'Business Entity',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.BusinessEntity,
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] end
	})
	public businessEntityId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public businessEntity: Models.BusinessEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Logo'] off begin
		name: 'Logo',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.LogoEntity,
		// % protected region % [Modify props to the crud options here for reference 'Logo'] end
	})
	public logoId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public logo: Models.LogoEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignWorkflows'] off begin
		name: 'ESignWorkflowss',
		displayType: 'reference-multicombobox',
		order: 80,
		referenceTypeFunc: () => Models.EsignworkflowEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'esignworkflowss',
			oppositeEntity: () => Models.EsignworkflowEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ESignWorkflows'] end
	})
	public esignworkflowss: Models.EsignworkflowEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@action public validateField = async (field: string) => {
		switch (field) {
			case 'name': {
				this.setFieldError('name', !this.name, 'Template Name cannot be empty');
				break;
			}
			case 'lifespanindays': {
				this.setFieldError('lifespanindays', !this.lifespanindays, 'Life span cannot be empty');
				if (this.lifespanindays <= 0) {
					this.setFieldError('lifespanindays', true, 'Life span cannot be less than 1');
					break;
				}
				if (this.lifespanindays > 10) {
					this.setFieldError('lifespanindays', true, 'Life span cannot exceed 10 days');
					break;
				}

				if (this.lifespanindays % 1 !== 0) {
					this.setFieldError('lifespanindays', true, 'Life span must be a whole number');
					break;
				}

				this.validateField('reminderfrequencyindays');
				break;
			}
			case 'reminderfrequencyindays': {
				if (this.reminderfrequencyindays < 0) {
					this.setFieldError('reminderfrequencyindays', true, 'Reminder Frequency cannot be empty');
				}

				if (this.lifespanindays <= this.reminderfrequencyindays) {
					this.setFieldError(
						'reminderfrequencyindays',
						true,
						'Reminder Frequency must be less than Life span',
					);
				}

				if (this.reminderfrequencyindays < this.lifespanindays) {
					this.setFieldError('reminderfrequencyindays', false, '');
				}

				break;
			}
			case 'minimumnumberofsignees': {
				this.setFieldError(
					'minimumnumberofsignees',
					!this.minimumnumberofsignees,
					'Minimum number of signee cannot be empty',
				);

				if (this.minimumnumberofsignees <= 0) {
					this.setFieldError(
						'minimumnumberofsignees',
						true,
						'Minimum number of signee cannot be less than 1',
					);
				}

				if (this.minimumnumberofsignees % 1 !== 0) {
					this.setFieldError(
						'minimumnumberofsignees',
						true,
						'Minimum number of signee must be a whole number',
					);
				}

				break;
			}
		}
	};

	public validateTemplateDetailsFields = async () => {
		const fieldList = [
			'name', 'lifespanindays', 'reminderfrequencyindays', 'minimumnumberofsignees',
		];
		const validationPromises = fieldList.map(field => this.validateField(field));
		await Promise.all(validationPromises);
	};
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IEsigntemplateEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IEsigntemplateEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.lifespanindays !== undefined) {
				this.lifespanindays = attributes.lifespanindays;
			}
			if (attributes.reminderfrequencyindays !== undefined) {
				this.reminderfrequencyindays = attributes.reminderfrequencyindays;
			}
			if (attributes.minimumnumberofsignees !== undefined) {
				this.minimumnumberofsignees = attributes.minimumnumberofsignees;
			}
			if (attributes.esigntemplatecontents !== undefined && Array.isArray(attributes.esigntemplatecontents)) {
				for (const model of attributes.esigntemplatecontents) {
					if (model instanceof Models.EsigntemplatecontentEntity) {
						this.esigntemplatecontents.push(model);
					} else {
						this.esigntemplatecontents.push(new Models.EsigntemplatecontentEntity(model));
					}
				}
			}
			if (attributes.businessEntityId !== undefined) {
				this.businessEntityId = attributes.businessEntityId;
			}
			if (attributes.businessEntity !== undefined) {
				if (attributes.businessEntity === null) {
					this.businessEntity = attributes.businessEntity;
				} else if (attributes.businessEntity instanceof Models.BusinessEntity) {
					this.businessEntity = attributes.businessEntity;
					this.businessEntityId = attributes.businessEntity.id;
				} else {
					this.businessEntity = new Models.BusinessEntity(attributes.businessEntity);
					this.businessEntityId = this.businessEntity.id;
				}
			}
			if (attributes.logoId !== undefined) {
				this.logoId = attributes.logoId;
			}
			if (attributes.logo !== undefined) {
				if (attributes.logo === null) {
					this.logo = attributes.logo;
				} else if (attributes.logo instanceof Models.LogoEntity) {
					this.logo = attributes.logo;
					this.logoId = attributes.logo.id;
				} else {
					this.logo = new Models.LogoEntity(attributes.logo);
					this.logoId = this.logo.id;
				}
			}
			if (attributes.esignworkflowss !== undefined && Array.isArray(attributes.esignworkflowss)) {
				for (const model of attributes.esignworkflowss) {
					if (model instanceof Models.EsignworkflowEntity) {
						this.esignworkflowss.push(model);
					} else {
						this.esignworkflowss.push(new Models.EsignworkflowEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		esigntemplatecontents {
			${Models.EsigntemplatecontentEntity.getAttributes().join('\n')}
		}
		businessEntity {
			${Models.BusinessEntity.getAttributes().join('\n')}
		}
		logo {
			${Models.LogoEntity.getAttributes().join('\n')}
			${Models.LogoEntity.getFiles().map(f => f.name).join('\n')}
		}
		esignworkflowss {
			${Models.EsignworkflowEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			esigntemplatecontents: {},
			esignworkflowss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(EsigntemplateEntity.prototype, 'created');
CRUD(modifiedAttr)(EsigntemplateEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
