/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsCustomerEntity from 'Models/Security/Acl/VisitorsCustomerEntity';
import UserCustomerEntity from 'Models/Security/Acl/UserCustomerEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { runInAction } from 'mobx';
import { isPhone } from 'Validators/Functions/Custom';
import { isEmail } from 'Validators/Functions/Email';
// eslint-disable-next-line object-curly-newline, max-len
import { isTypeOfState, validateLiquorLicenceNumber, verifyAbn, verifyAcn } from 'Util/StringUtils';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import type { LiquorLicenceDetails } from 'Types/liquorLicenceValidationResponse';
// % protected region % [Add any further imports here] end

export interface ICustomerEntityAttributes extends IModelAttributes {
	drinksLicence: string;
	entityTypeDescription: string;
	email: string;
	addedToWatchlist: boolean;
	acnEntityName: string;
	acnClass: string;
	trusteeACNStatus: string;
	acnLastUpdated: Date;
	trusteeACNClass: string;
	tradingTerms: string;
	purchasingOfficerEmail: string;
	acnEntityTypeCode: string;
	taxInvoicesEmail: string;
	trusteeACN: string;
	statementsEmail: string;
	acn: string;
	abnActive: boolean;
	trusteeACNEntityTypeCode: string;
	accountsPhoneNumber: string;
	creditLimit: string;
	activeSince: Date;
	acnSubClass: string;
	abn: string;
	recordUpdated: Date;
	businessName: string;
	trusteeACNSubClass: string;
	entityTypeCode: string;
	debtorID: string;
	locality: string;
	entityStatus: string;
	dateOfBirth: Date;
	trusteeACNLastUpdated: Date;
	trusteeACNRegistrationDate: string;
	firstName: string;
	registeredForGSTFrom: string;
	nameForInvoice: string;
	acnRegistrationDate: string;
	entityName: string;
	phone: string;
	taxInvoicesContact: string;
	drinksLicenceLastUpdated: Date;
	paymentRiskRating: Enums.riskScore;
	acnStatus: string;
	purchasingOfficer: string;
	statementsContact: string;
	sicCode: string;
	drinksLicenceInfo: string;
	trusteeACNEntityName: string;
	trusteeName: string;
	drinksLicenceState: Enums.state;
	lastName: string;
	abrLastUpdated: Date;
	monitoredStatus: Enums.customerMonitoredStatus;
	drinksLicenceStatus: string;
	accountsContact: string;

	atbRecords: Array<
		| Models.AtbRecordEntity
		| Models.IAtbRecordEntityAttributes
	>;
	alaress: Array<
		| Models.AlaresEntity
		| Models.IAlaresEntityAttributes
	>;
	customerAuditss: Array<
		| Models.CustomerAuditEntity
		| Models.ICustomerAuditEntityAttributes
	>;
	businessEntityId: string;
	businessEntity: Models.BusinessEntity | Models.IBusinessEntityAttributes;
	groupss: Array<
		| Models.GroupsCustomers
		| Models.IGroupsCustomersAttributes
	>;
	monitoredss: Array<
		| Models.MonitoredsCustomers
		| Models.IMonitoredsCustomersAttributes
	>;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	physicalAddress?: Models.AddressEntity |
		Models.IAddressEntityAttributes;
	postalAddress?: Models.AddressEntity |
		Models.IAddressEntityAttributes;
	reportRequests: Array<
		| Models.ReportRequestEntity
		| Models.IReportRequestEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	newestAtbRecord: Models.AtbRecordEntity;
	newestAlares: Models.AlaresEntity;
	abnFinishedValidating: boolean;
	acnFinishedValidating: boolean;
	trusteeACNFinishedValidating: boolean;
	businessEntityStatus: boolean;
	liquorLicenceFinishedValidating: boolean;
	// eslint-disable-next-line max-len
	liquorLicenceResponseDetails?: { data: Record<string, LiquorLicenceDetails>; responseCode: number; responseMessage: string };
	selectedLiquorLicenceDetails?: LiquorLicenceDetails;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CustomerEntity', 'Customer')
// % protected region % [Customise your entity metadata here] end
export default class CustomerEntity extends Model
	implements ICustomerEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsCustomerEntity(),
		new UserCustomerEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] on begin
		'newestAtbRecord',
		'newestAlares',
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] on begin
		'newestAtbRecord',
		'newestAlares',
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Drinks Licence'] off begin
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Drinks Licence',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public drinksLicence: string;
	// % protected region % [Modify props to the crud options here for attribute 'Drinks Licence'] end

	// % protected region % [Modify props to the crud options here for attribute 'Entity Type Description'] off begin
	/**
	 * Entity Type Description
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Entity Type Description',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public entityTypeDescription: string;
	// % protected region % [Modify props to the crud options here for attribute 'Entity Type Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Email'] off begin
	/**
	 * Email
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Email',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Added To Watchlist'] off begin
	/**
	 * Alares Watchlist has been created for Customer
	 */
	@observable
	@attribute<CustomerEntity, boolean>()
	@CRUD({
		name: 'Added To Watchlist',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public addedToWatchlist: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Added To Watchlist'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Entity Name'] off begin
	/**
	 * The entity name sent back in the ACN response
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'ACN Entity Name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnEntityName: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Entity Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Class'] off begin
	/**
	 * ACN Class from external endpoint
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'ACN Class',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnClass: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Class'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Status'] off begin
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Trustee ACN Status',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeACNStatus: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Last Updated'] off begin
	@observable
	@attribute<CustomerEntity, Date>()
	@CRUD({
		name: 'ACN Last Updated',
		displayType: 'datetimepicker',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public acnLastUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Last Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Class'] off begin
	/**
	 * ACN Class from external endpoint
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Trustee ACN Class',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeACNClass: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Class'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trading terms'] off begin
	/**
	 * Trading terms
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Trading terms',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public tradingTerms: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trading terms'] end

	// % protected region % [Modify props to the crud options here for attribute 'Purchasing Officer Email'] off begin
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Purchasing Officer Email',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public purchasingOfficerEmail: string;
	// % protected region % [Modify props to the crud options here for attribute 'Purchasing Officer Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Entity Type Code'] off begin
	/**
	 * The entity type code sent back in the ACN response
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'ACN Entity Type Code',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnEntityTypeCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Entity Type Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Tax Invoices Email'] off begin
	/**
	 * Tax Invoices Email
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Tax Invoices Email',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public taxInvoicesEmail: string;
	// % protected region % [Modify props to the crud options here for attribute 'Tax Invoices Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN'] off begin
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Trustee ACN',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeACN: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN'] end

	// % protected region % [Modify props to the crud options here for attribute 'Statements Email'] off begin
	/**
	 * Statements Email
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Statements Email',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public statementsEmail: string;
	// % protected region % [Modify props to the crud options here for attribute 'Statements Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN'] off begin
	/**
	 * ACN
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'ACN',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acn: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN Active'] off begin
	/**
	 * ABN is current indicator
	 */
	@observable
	@attribute<CustomerEntity, boolean>()
	@CRUD({
		name: 'ABN Active',
		displayType: 'checkbox',
		order: 170,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public abnActive: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'ABN Active'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Entity Type Code'] off begin
	/**
	 * The entity type code for the trustee ACN reponse
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Trustee ACN Entity Type Code',
		displayType: 'textfield',
		order: 180,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeACNEntityTypeCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Entity Type Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Accounts Phone Number'] off begin
	/**
	 * Accounts Phone Number
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Accounts Phone Number',
		displayType: 'textfield',
		order: 190,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public accountsPhoneNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Accounts Phone Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Credit Limit'] off begin
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Credit Limit',
		displayType: 'textfield',
		order: 200,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public creditLimit: string;
	// % protected region % [Modify props to the crud options here for attribute 'Credit Limit'] end

	// % protected region % [Modify props to the crud options here for attribute 'Active Since'] off begin
	/**
	 * Active Since
	 */
	@observable
	@attribute<CustomerEntity, Date>()
	@CRUD({
		name: 'Active Since',
		displayType: 'datetimepicker',
		order: 210,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public activeSince: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Active Since'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Sub Class'] off begin
	/**
	 * ACN Sub Class from external endpoint
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'ACN Sub Class',
		displayType: 'textfield',
		order: 220,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnSubClass: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Sub Class'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN'] off begin
	/**
	 * ABN
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'ABN',
		displayType: 'textfield',
		order: 230,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abn: string;
	// % protected region % [Modify props to the crud options here for attribute 'ABN'] end

	// % protected region % [Modify props to the crud options here for attribute 'Record Updated'] off begin
	/**
	 * Record Updated
	 */
	@observable
	@attribute<CustomerEntity, Date>()
	@CRUD({
		name: 'Record Updated',
		displayType: 'datetimepicker',
		order: 240,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public recordUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Record Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'Business Name'] off begin
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Business Name',
		displayType: 'textfield',
		order: 250,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public businessName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Business Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Sub Class'] off begin
	/**
	 * Trustee ACN Sub Class from external endpoint
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Trustee ACN Sub Class',
		displayType: 'textfield',
		order: 260,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeACNSubClass: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Sub Class'] end

	// % protected region % [Modify props to the crud options here for attribute 'Entity Type Code'] off begin
	/**
	 * Entity Type Code
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Entity Type Code',
		displayType: 'textfield',
		order: 270,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public entityTypeCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Entity Type Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Debtor ID'] off begin
	/**
	 * A customer's debtor ID represents the unique identifier used by business entities to represent their customers
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Debtor ID',
		displayType: 'textfield',
		order: 280,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public debtorID: string;
	// % protected region % [Modify props to the crud options here for attribute 'Debtor ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Locality'] off begin
	/**
	 * Locality
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Locality',
		displayType: 'textfield',
		order: 290,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public locality: string;
	// % protected region % [Modify props to the crud options here for attribute 'Locality'] end

	// % protected region % [Modify props to the crud options here for attribute 'Entity Status'] off begin
	/**
	 * Entity Status
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Entity Status',
		displayType: 'textfield',
		order: 300,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public entityStatus: string;
	// % protected region % [Modify props to the crud options here for attribute 'Entity Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Date of Birth'] off begin
	/**
	 * Date of Birth
	 */
	@observable
	@attribute<CustomerEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Date of Birth',
		displayType: 'datepicker',
		order: 310,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public dateOfBirth: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Date of Birth'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Last Updated'] off begin
	@observable
	@attribute<CustomerEntity, Date>()
	@CRUD({
		name: 'Trustee ACN Last Updated',
		displayType: 'datetimepicker',
		order: 320,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public trusteeACNLastUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Last Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Registration Date'] off begin
	/**
	 * Trustee ACN Registration Date from external endpoint
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Trustee ACN Registration Date',
		displayType: 'textfield',
		order: 330,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeACNRegistrationDate: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Registration Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'First Name'] off begin
	/**
	 * First Name
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'First Name',
		displayType: 'textfield',
		order: 340,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'First Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Registered for GST From'] off begin
	/**
	 * Registered for GST From
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Registered for GST From',
		displayType: 'textfield',
		order: 350,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public registeredForGSTFrom: string;
	// % protected region % [Modify props to the crud options here for attribute 'Registered for GST From'] end

	// % protected region % [Modify props to the crud options here for attribute 'Name for Invoice'] off begin
	/**
	 * Name for Invoice
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Name for Invoice',
		displayType: 'textfield',
		order: 360,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public nameForInvoice: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name for Invoice'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Registration Date'] off begin
	/**
	 * ACN Registration Date from external endpoint
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'ACN Registration Date',
		displayType: 'textfield',
		order: 370,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnRegistrationDate: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Registration Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Entity Name'] off begin
	/**
	 * Entity Name
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Entity Name',
		displayType: 'textfield',
		order: 380,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public entityName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Entity Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] off begin
	/**
	 * Phone
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Phone',
		displayType: 'textfield',
		order: 390,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Tax Invoices Contact'] off begin
	/**
	 * Tax Invoices Contact
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Tax Invoices Contact',
		displayType: 'textfield',
		order: 400,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public taxInvoicesContact: string;
	// % protected region % [Modify props to the crud options here for attribute 'Tax Invoices Contact'] end

	// % protected region % [Modify props to the crud options here for attribute 'Drinks Licence Last Updated'] off begin
	@observable
	@attribute<CustomerEntity, Date>()
	@CRUD({
		name: 'Drinks Licence Last Updated',
		displayType: 'datetimepicker',
		order: 410,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public drinksLicenceLastUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Drinks Licence Last Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'Payment Risk Rating'] off begin
	@observable
	@attribute<CustomerEntity, Enums.riskScore>()
	@CRUD({
		name: 'Payment Risk Rating',
		displayType: 'enum-combobox',
		order: 420,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.riskScoreOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.riskScoreOptions),
		displayFunction: (attr: Enums.riskScore) => Enums.riskScoreOptions[attr],
	})
	public paymentRiskRating: Enums.riskScore;
	// % protected region % [Modify props to the crud options here for attribute 'Payment Risk Rating'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Status'] off begin
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'ACN Status',
		displayType: 'textfield',
		order: 430,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnStatus: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Purchasing Officer'] off begin
	/**
	 * Purchasing Officer
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Purchasing Officer',
		displayType: 'textfield',
		order: 440,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public purchasingOfficer: string;
	// % protected region % [Modify props to the crud options here for attribute 'Purchasing Officer'] end

	// % protected region % [Modify props to the crud options here for attribute 'Statements Contact'] off begin
	/**
	 * Statements Contact
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Statements Contact',
		displayType: 'textfield',
		order: 450,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public statementsContact: string;
	// % protected region % [Modify props to the crud options here for attribute 'Statements Contact'] end

	// % protected region % [Modify props to the crud options here for attribute 'Sic Code'] off begin
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Sic Code',
		displayType: 'textfield',
		order: 460,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public sicCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Sic Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Drinks Licence Info'] off begin
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Drinks Licence Info',
		displayType: 'textfield',
		order: 470,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public drinksLicenceInfo: string;
	// % protected region % [Modify props to the crud options here for attribute 'Drinks Licence Info'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Entity Name'] off begin
	/**
	 * The entity name sent back by the trustee ACN response
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Trustee ACN Entity Name',
		displayType: 'textfield',
		order: 480,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeACNEntityName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN Entity Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee Name'] off begin
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Trustee Name',
		displayType: 'textfield',
		order: 490,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Drinks Licence State'] off begin
	@observable
	@attribute<CustomerEntity, Enums.state>()
	@CRUD({
		name: 'Drinks Licence State',
		displayType: 'enum-combobox',
		order: 500,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.stateOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.stateOptions),
		displayFunction: (attr: Enums.state) => Enums.stateOptions[attr],
	})
	public drinksLicenceState: Enums.state;
	// % protected region % [Modify props to the crud options here for attribute 'Drinks Licence State'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] off begin
	/**
	 * Last Name
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Last Name',
		displayType: 'textfield',
		order: 510,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABR Last Updated'] off begin
	/**
	 * ABR Last Updated
	 */
	@observable
	@attribute<CustomerEntity, Date>()
	@CRUD({
		name: 'ABR Last Updated',
		displayType: 'datetimepicker',
		order: 520,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public abrLastUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'ABR Last Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'Monitored Status'] off begin
	/**
	 * If the customer is being actively monitored, in a dormant state, or insolvent
	 */
	@observable
	@attribute<CustomerEntity, Enums.customerMonitoredStatus>()
	@CRUD({
		name: 'Monitored Status',
		displayType: 'enum-combobox',
		order: 530,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.customerMonitoredStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.customerMonitoredStatusOptions),
		displayFunction: (attr: Enums.customerMonitoredStatus) => Enums.customerMonitoredStatusOptions[attr],
	})
	public monitoredStatus: Enums.customerMonitoredStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Monitored Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Drinks Licence Status'] off begin
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Drinks Licence Status',
		displayType: 'textfield',
		order: 540,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public drinksLicenceStatus: string;
	// % protected region % [Modify props to the crud options here for attribute 'Drinks Licence Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Accounts Contact'] off begin
	/**
	 * Accounts Contact
	 */
	@observable
	@attribute<CustomerEntity, string>()
	@CRUD({
		name: 'Accounts Contact',
		displayType: 'textfield',
		order: 550,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public accountsContact: string;
	// % protected region % [Modify props to the crud options here for attribute 'Accounts Contact'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ATB Record'] off begin
		name: 'ATB Records',
		displayType: 'reference-multicombobox',
		order: 560,
		referenceTypeFunc: () => Models.AtbRecordEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'atbRecords',
			oppositeEntity: () => Models.AtbRecordEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ATB Record'] end
	})
	public atbRecords: Models.AtbRecordEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Alares'] off begin
		name: 'Alaress',
		displayType: 'reference-multicombobox',
		order: 570,
		referenceTypeFunc: () => Models.AlaresEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'alaress',
			oppositeEntity: () => Models.AlaresEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Alares'] end
	})
	public alaress: Models.AlaresEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Customer Audits'] off begin
		name: 'Customer Auditss',
		displayType: 'reference-multicombobox',
		order: 580,
		referenceTypeFunc: () => Models.CustomerAuditEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'customerAuditss',
			oppositeEntity: () => Models.CustomerAuditEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Customer Audits'] end
	})
	public customerAuditss: Models.CustomerAuditEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] off begin
		name: 'Business Entity',
		displayType: 'reference-combobox',
		order: 590,
		referenceTypeFunc: () => Models.BusinessEntity,
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] end
	})
	public businessEntityId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public businessEntity: Models.BusinessEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Groups'] off begin
		name: 'Groups',
		displayType: 'reference-multicombobox',
		order: 600,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.GroupsCustomers,
		optionEqualFunc: makeJoinEqualsFunc('groupsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'customerEntity',
			oppositeEntityName: 'groupEntity',
			relationName: 'customers',
			relationOppositeName: 'groups',
			entity: () => Models.CustomerEntity,
			joinEntity: () => Models.GroupsCustomers,
			oppositeEntity: () => Models.GroupEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Groups'] end
	})
	public groupss: Models.GroupsCustomers[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Monitoreds'] off begin
		name: 'Monitoreds',
		displayType: 'reference-multicombobox',
		order: 610,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.MonitoredsCustomers,
		optionEqualFunc: makeJoinEqualsFunc('monitoredsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'customerEntity',
			oppositeEntityName: 'monitoredEntity',
			relationName: 'customers',
			relationOppositeName: 'monitoreds',
			entity: () => Models.CustomerEntity,
			joinEntity: () => Models.MonitoredsCustomers,
			oppositeEntity: () => Models.MonitoredEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Monitoreds'] end
	})
	public monitoredss: Models.MonitoredsCustomers[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 620,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	/**
	 * Physical Address
	 */
	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Physical Address'] off begin
		name: 'Physical Address',
		displayType: 'reference-combobox',
		order: 630,
		referenceTypeFunc: () => Models.AddressEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'physicalAddresss',
			oppositeEntity: () => Models.AddressEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Physical Address'] end
	})
	public physicalAddress?: Models.AddressEntity;

	/**
	 * Postal Address
	 */
	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Postal Address'] off begin
		name: 'Postal Address',
		displayType: 'reference-combobox',
		order: 640,
		referenceTypeFunc: () => Models.AddressEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'postalAddresss',
			oppositeEntity: () => Models.AddressEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Postal Address'] end
	})
	public postalAddress?: Models.AddressEntity;

	@Validators.Length(1)
	@Validators.Required()
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] off begin
		name: 'Report Requests',
		displayType: 'reference-multicombobox',
		order: 650,
		referenceTypeFunc: () => Models.ReportRequestEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'reportRequests',
			oppositeEntity: () => Models.ReportRequestEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] end
	})
	public reportRequests: Models.ReportRequestEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable public abnFinishedValidating: boolean = false;
	@observable public acnFinishedValidating: boolean = false;
	@observable public trusteeACNFinishedValidating: boolean = false;
	@observable public businessEntityStatus: boolean;
	@observable public liquorLicenceFinishedValidating: boolean = false;
	// eslint-disable-next-line max-len
	@observable public liquorLicenceResponseDetails?: {data: Record<string, LiquorLicenceDetails>; responseCode: number; responseMessage: string };
	@observable public selectedLiquorLicenceDetails?: LiquorLicenceDetails;
	/**
	 * The payment risk rating for the customer given this set of ATB data
	 */
	@observable public newestAtbRecord: Models.AtbRecordEntity;
	@observable public newestAlares: Models.AlaresEntity;

	@action public validateField = async (field: string) => {
		switch (field) {
			case 'accountsPhoneNumber': {
				this.setFieldError('accountsPhoneNumber', !!this.accountsPhoneNumber
					&& !isPhone(this.accountsPhoneNumber), 'Accounts contact number must be a valid phone number');
				break;
			}
			case 'taxInvoicesEmail': {
				this.setFieldError('taxInvoicesEmail', !!this.taxInvoicesEmail
					&& !isEmail(this.taxInvoicesEmail), 'Tax invoice email must be a valid email address');
				break;
			}
			case 'statementsEmail': {
				this.setFieldError(
					'statementsEmail',
					!!this.statementsEmail && !isEmail(this.statementsEmail),
					'Statements email must be a valid email address',
				);
				break;
			}
			case 'purchasingOfficerEmail': {
				this.setFieldError('purchasingOfficerEmail', !!this.purchasingOfficerEmail
					&& !isEmail(this.purchasingOfficerEmail), 'Purchasing officer email must be a valid email address');
				break;
			}
			case 'physicalAddress.postcode': {
				// Borrow errors from the address entity
				this.physicalAddress?.validateField('postcode');
				break;
			}
			case 'postalAddress.postcode': {
				// Borrow errors from the address entity
				this.postalAddress?.validateField('postcode');
				break;
			}
			case 'abn': {
				await this.runAbnValidation();
				break;
			}
			case 'acn': {
				await this.runAcnValidation();
				break;
			}
			case 'trusteeACN': {
				await this.runTrusteeACNValidation();
				break;
			}
			case 'businessName': {
				this.setFieldError('businessName', !this.businessName, 'Business name must be set');
				break;
			}
			case 'businessEntity': {
				this.setFieldError('businessEntity', !this.businessEntityId, 'Business Entity must be selected');
				break;
			}
			case 'drinksLicence': {
				await this.runLiquorLicenceValidation();
				break;
			}
			case 'debtorID': {
				if (!!this.debtorID) {
					if (!this.businessEntityId) {
						this.setFieldError('businessEntity', true, 'Business Entity must be selected');
						break;
					}
					const { data } = await axios.get(`${SERVER_URL}/api/entity/CustomerEntity/validate-debtor-id/
						${this.businessEntityId}/${this.debtorID}`);
					this.setFieldError('debtorID', data !== true, 'Debtor ID must be unique per customer');
					break;
				}
			}
		}
	};

	public validateAccountInformationFields = async () => {
		const fieldList = [
			'accountsPhoneNumber', 'taxInvoicesEmail', 'statementsEmail', 'physicalAddress.postcode',
			'postalAddress.postcode',
		];
		const validationPromises = fieldList.map(field => this.validateField(field));
		await Promise.all(validationPromises);
	};

	public validateAddCustomerFields = async () => {
		const fieldList = [
			'abn', 'acn', 'businessName', 'businessEntity', 'debtorID',
		];
		const validationPromises = fieldList.map(field => this.validateField(field));
		await Promise.all(validationPromises);
	};
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICustomerEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICustomerEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.drinksLicence !== undefined) {
				this.drinksLicence = attributes.drinksLicence;
			}
			if (attributes.entityTypeDescription !== undefined) {
				this.entityTypeDescription = attributes.entityTypeDescription;
			}
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.addedToWatchlist !== undefined) {
				this.addedToWatchlist = attributes.addedToWatchlist;
			}
			if (attributes.acnEntityName !== undefined) {
				this.acnEntityName = attributes.acnEntityName;
			}
			if (attributes.acnClass !== undefined) {
				this.acnClass = attributes.acnClass;
			}
			if (attributes.trusteeACNStatus !== undefined) {
				this.trusteeACNStatus = attributes.trusteeACNStatus;
			}
			if (attributes.acnLastUpdated !== undefined) {
				if (attributes.acnLastUpdated === null) {
					this.acnLastUpdated = attributes.acnLastUpdated;
				} else {
					this.acnLastUpdated = moment(attributes.acnLastUpdated).toDate();
				}
			}
			if (attributes.trusteeACNClass !== undefined) {
				this.trusteeACNClass = attributes.trusteeACNClass;
			}
			if (attributes.tradingTerms !== undefined) {
				this.tradingTerms = attributes.tradingTerms;
			}
			if (attributes.purchasingOfficerEmail !== undefined) {
				this.purchasingOfficerEmail = attributes.purchasingOfficerEmail;
			}
			if (attributes.acnEntityTypeCode !== undefined) {
				this.acnEntityTypeCode = attributes.acnEntityTypeCode;
			}
			if (attributes.taxInvoicesEmail !== undefined) {
				this.taxInvoicesEmail = attributes.taxInvoicesEmail;
			}
			if (attributes.trusteeACN !== undefined) {
				this.trusteeACN = attributes.trusteeACN;
			}
			if (attributes.statementsEmail !== undefined) {
				this.statementsEmail = attributes.statementsEmail;
			}
			if (attributes.acn !== undefined) {
				this.acn = attributes.acn;
			}
			if (attributes.abnActive !== undefined) {
				this.abnActive = attributes.abnActive;
			}
			if (attributes.trusteeACNEntityTypeCode !== undefined) {
				this.trusteeACNEntityTypeCode = attributes.trusteeACNEntityTypeCode;
			}
			if (attributes.accountsPhoneNumber !== undefined) {
				this.accountsPhoneNumber = attributes.accountsPhoneNumber;
			}
			if (attributes.creditLimit !== undefined) {
				this.creditLimit = attributes.creditLimit;
			}
			if (attributes.activeSince !== undefined) {
				if (attributes.activeSince === null) {
					this.activeSince = attributes.activeSince;
				} else {
					this.activeSince = moment(attributes.activeSince).toDate();
				}
			}
			if (attributes.acnSubClass !== undefined) {
				this.acnSubClass = attributes.acnSubClass;
			}
			if (attributes.abn !== undefined) {
				this.abn = attributes.abn;
			}
			if (attributes.recordUpdated !== undefined) {
				if (attributes.recordUpdated === null) {
					this.recordUpdated = attributes.recordUpdated;
				} else {
					this.recordUpdated = moment(attributes.recordUpdated).toDate();
				}
			}
			if (attributes.businessName !== undefined) {
				this.businessName = attributes.businessName;
			}
			if (attributes.trusteeACNSubClass !== undefined) {
				this.trusteeACNSubClass = attributes.trusteeACNSubClass;
			}
			if (attributes.entityTypeCode !== undefined) {
				this.entityTypeCode = attributes.entityTypeCode;
			}
			if (attributes.debtorID !== undefined) {
				this.debtorID = attributes.debtorID;
			}
			if (attributes.locality !== undefined) {
				this.locality = attributes.locality;
			}
			if (attributes.entityStatus !== undefined) {
				this.entityStatus = attributes.entityStatus;
			}
			if (attributes.dateOfBirth !== undefined) {
				if (attributes.dateOfBirth === null) {
					this.dateOfBirth = attributes.dateOfBirth;
				} else {
					this.dateOfBirth = moment(attributes.dateOfBirth).toDate();
				}
			}
			if (attributes.trusteeACNLastUpdated !== undefined) {
				if (attributes.trusteeACNLastUpdated === null) {
					this.trusteeACNLastUpdated = attributes.trusteeACNLastUpdated;
				} else {
					this.trusteeACNLastUpdated = moment(attributes.trusteeACNLastUpdated).toDate();
				}
			}
			if (attributes.trusteeACNRegistrationDate !== undefined) {
				this.trusteeACNRegistrationDate = attributes.trusteeACNRegistrationDate;
			}
			if (attributes.firstName !== undefined) {
				this.firstName = attributes.firstName;
			}
			if (attributes.registeredForGSTFrom !== undefined) {
				this.registeredForGSTFrom = attributes.registeredForGSTFrom;
			}
			if (attributes.nameForInvoice !== undefined) {
				this.nameForInvoice = attributes.nameForInvoice;
			}
			if (attributes.acnRegistrationDate !== undefined) {
				this.acnRegistrationDate = attributes.acnRegistrationDate;
			}
			if (attributes.entityName !== undefined) {
				this.entityName = attributes.entityName;
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.taxInvoicesContact !== undefined) {
				this.taxInvoicesContact = attributes.taxInvoicesContact;
			}
			if (attributes.drinksLicenceLastUpdated !== undefined) {
				if (attributes.drinksLicenceLastUpdated === null) {
					this.drinksLicenceLastUpdated = attributes.drinksLicenceLastUpdated;
				} else {
					this.drinksLicenceLastUpdated = moment(attributes.drinksLicenceLastUpdated).toDate();
				}
			}
			if (attributes.paymentRiskRating !== undefined) {
				this.paymentRiskRating = attributes.paymentRiskRating;
			}
			if (attributes.acnStatus !== undefined) {
				this.acnStatus = attributes.acnStatus;
			}
			if (attributes.purchasingOfficer !== undefined) {
				this.purchasingOfficer = attributes.purchasingOfficer;
			}
			if (attributes.statementsContact !== undefined) {
				this.statementsContact = attributes.statementsContact;
			}
			if (attributes.sicCode !== undefined) {
				this.sicCode = attributes.sicCode;
			}
			if (attributes.drinksLicenceInfo !== undefined) {
				this.drinksLicenceInfo = attributes.drinksLicenceInfo;
			}
			if (attributes.trusteeACNEntityName !== undefined) {
				this.trusteeACNEntityName = attributes.trusteeACNEntityName;
			}
			if (attributes.trusteeName !== undefined) {
				this.trusteeName = attributes.trusteeName;
			}
			if (attributes.drinksLicenceState !== undefined) {
				this.drinksLicenceState = attributes.drinksLicenceState;
			}
			if (attributes.lastName !== undefined) {
				this.lastName = attributes.lastName;
			}
			if (attributes.abrLastUpdated !== undefined) {
				if (attributes.abrLastUpdated === null) {
					this.abrLastUpdated = attributes.abrLastUpdated;
				} else {
					this.abrLastUpdated = moment(attributes.abrLastUpdated).toDate();
				}
			}
			if (attributes.monitoredStatus !== undefined) {
				this.monitoredStatus = attributes.monitoredStatus;
			}
			if (attributes.drinksLicenceStatus !== undefined) {
				this.drinksLicenceStatus = attributes.drinksLicenceStatus;
			}
			if (attributes.accountsContact !== undefined) {
				this.accountsContact = attributes.accountsContact;
			}
			if (attributes.atbRecords !== undefined && Array.isArray(attributes.atbRecords)) {
				for (const model of attributes.atbRecords) {
					if (model instanceof Models.AtbRecordEntity) {
						this.atbRecords.push(model);
					} else {
						this.atbRecords.push(new Models.AtbRecordEntity(model));
					}
				}
			}
			if (attributes.alaress !== undefined && Array.isArray(attributes.alaress)) {
				for (const model of attributes.alaress) {
					if (model instanceof Models.AlaresEntity) {
						this.alaress.push(model);
					} else {
						this.alaress.push(new Models.AlaresEntity(model));
					}
				}
			}
			if (attributes.customerAuditss !== undefined && Array.isArray(attributes.customerAuditss)) {
				for (const model of attributes.customerAuditss) {
					if (model instanceof Models.CustomerAuditEntity) {
						this.customerAuditss.push(model);
					} else {
						this.customerAuditss.push(new Models.CustomerAuditEntity(model));
					}
				}
			}
			if (attributes.businessEntityId !== undefined) {
				this.businessEntityId = attributes.businessEntityId;
			}
			if (attributes.businessEntity !== undefined) {
				if (attributes.businessEntity === null) {
					this.businessEntity = attributes.businessEntity;
				} else if (attributes.businessEntity instanceof Models.BusinessEntity) {
					this.businessEntity = attributes.businessEntity;
					this.businessEntityId = attributes.businessEntity.id;
				} else {
					this.businessEntity = new Models.BusinessEntity(attributes.businessEntity);
					this.businessEntityId = this.businessEntity.id;
				}
			}
			if (attributes.groupss !== undefined && Array.isArray(attributes.groupss)) {
				for (const model of attributes.groupss) {
					if (model instanceof Models.GroupsCustomers) {
						this.groupss.push(model);
					} else {
						this.groupss.push(new Models.GroupsCustomers(model));
					}
				}
			}
			if (attributes.monitoredss !== undefined && Array.isArray(attributes.monitoredss)) {
				for (const model of attributes.monitoredss) {
					if (model instanceof Models.MonitoredsCustomers) {
						this.monitoredss.push(model);
					} else {
						this.monitoredss.push(new Models.MonitoredsCustomers(model));
					}
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.physicalAddress !== undefined) {
				if (attributes.physicalAddress === null) {
					this.physicalAddress = attributes.physicalAddress;
				} else if (attributes.physicalAddress instanceof Models.AddressEntity) {
					this.physicalAddress = attributes.physicalAddress;
				} else {
					this.physicalAddress = new Models.AddressEntity(attributes.physicalAddress);
				}
			}
			if (attributes.postalAddress !== undefined) {
				if (attributes.postalAddress === null) {
					this.postalAddress = attributes.postalAddress;
				} else if (attributes.postalAddress instanceof Models.AddressEntity) {
					this.postalAddress = attributes.postalAddress;
				} else {
					this.postalAddress = new Models.AddressEntity(attributes.postalAddress);
				}
			}
			if (attributes.reportRequests !== undefined && Array.isArray(attributes.reportRequests)) {
				for (const model of attributes.reportRequests) {
					if (model instanceof Models.ReportRequestEntity) {
						this.reportRequests.push(model);
					} else {
						this.reportRequests.push(new Models.ReportRequestEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes?.newestAtbRecord !== undefined) {
				if (attributes.newestAtbRecord === null) {
					this.newestAtbRecord = attributes.newestAtbRecord;
				} else if (attributes.newestAtbRecord instanceof Models.AtbRecordEntity) {
					this.newestAtbRecord = attributes.newestAtbRecord;
				} else {
					this.newestAtbRecord = new Models.AtbRecordEntity(attributes.newestAtbRecord);
				}
			}
			if (attributes.newestAlares !== undefined) {
				if (attributes.newestAlares === null) {
					this.newestAlares = attributes.newestAlares;
				} else if (attributes.newestAlares instanceof Models.AlaresEntity) {
					this.newestAlares = attributes.newestAlares;
				} else {
					this.newestAlares = new Models.AlaresEntity(attributes.newestAlares);
				}
			}
			if (attributes?.abnFinishedValidating !== undefined) {
				this.abnFinishedValidating = attributes.abnFinishedValidating;
			}
			if (attributes?.acnFinishedValidating !== undefined) {
				this.acnFinishedValidating = attributes.acnFinishedValidating;
			}
			if (attributes?.trusteeACNFinishedValidating !== undefined) {
				this.trusteeACNFinishedValidating = attributes.trusteeACNFinishedValidating;
			}
			if (attributes?.businessEntityStatus !== undefined) {
				this.businessEntityStatus = attributes.businessEntityStatus;
			}
			if (attributes?.liquorLicenceFinishedValidating !== undefined) {
				this.liquorLicenceFinishedValidating = attributes.liquorLicenceFinishedValidating;
			}

			if (attributes?.liquorLicenceResponseDetails !== undefined) {
				this.liquorLicenceResponseDetails = attributes.liquorLicenceResponseDetails;
			}

			if (attributes?.selectedLiquorLicenceDetails !== undefined) {
				this.selectedLiquorLicenceDetails = attributes.selectedLiquorLicenceDetails;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		groupss {
			${Models.GroupsCustomers.getAttributes().join('\n')}
			groups {
				${Models.GroupEntity.getAttributes().join('\n')}
			}
		}
		monitoredss {
			${Models.MonitoredsCustomers.getAttributes().join('\n')}
			monitoreds {
				${Models.MonitoredEntity.getAttributes().join('\n')}
			}
		}
		atbRecords {
			${Models.AtbRecordEntity.getAttributes().join('\n')}
		}
		alaress {
			${Models.AlaresEntity.getAttributes().join('\n')}
		}
		customerAuditss {
			${Models.CustomerAuditEntity.getAttributes().join('\n')}
		}
		businessEntity {
			${Models.BusinessEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
			${Models.OrganisationEntity.getFiles().map(f => f.name).join('\n')}
		}
		physicalAddress {
			${Models.AddressEntity.getAttributes().join('\n')}
		}
		postalAddress {
			${Models.AddressEntity.getAttributes().join('\n')}
		}
		reportRequests {
			${Models.ReportRequestEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			groupss: {},
			monitoredss: {},
			atbRecords: {},
			alaress: {},
			customerAuditss: {},
			physicalAddress: {},
			postalAddress: {},
			reportRequests: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'atbRecords',
							'groupss',
							'monitoredss',
							'physicalAddress',
							'postalAddress',
							'reportRequests',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.businessName ?? this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	// function for clearing the abr fields on this customer entity
	@action
	clearAbrFields() {
		this.entityTypeCode = '';
		this.entityTypeDescription = '';
		this.entityName = '';
		this.registeredForGSTFrom = '';
		this.locality = '';
		// @ts-ignore
		this.activeSince = undefined;
		// @ts-ignore
		this.recordUpdated = undefined;
		// @ts-ignore
		this.abnActive = undefined;
		this.entityStatus = '';
		this.abrLastUpdated = moment().utc().toDate();
	}

	// function for clearing the acn fields on this customer entity
	@action
	clearAcnFields() {
		this.acnClass = '';
		this.acnSubClass = '';
		this.acnRegistrationDate = '';
		this.acnEntityName = '';
		this.acnLastUpdated = moment().utc().toDate();
		this.acnEntityTypeCode = '';
		this.acnStatus = '';
	}

	// function for clearing the acn fields on this customer entity
	@action
	clearTrusteeAcnFields() {
		this.trusteeACNClass = '';
		this.trusteeACNSubClass = '';
		this.trusteeACNRegistrationDate = '';
		this.trusteeACNEntityName = '';
		this.trusteeACNLastUpdated = moment().utc().toDate();
		this.trusteeACNEntityTypeCode = '';
		this.trusteeACNStatus = '';
	}

	@action
	clearLiquorLicenceFields() {
		this.drinksLicenceLastUpdated = new Date(1001, 0, 1);
		this.drinksLicenceStatus = '';
		this.drinksLicenceInfo = '';
		this.liquorLicenceResponseDetails = undefined;
		this.selectedLiquorLicenceDetails = undefined;
	}

	@action
	updateLiquorLicenceFields(uniqueId: string) {
		const responseCode = this.liquorLicenceResponseDetails?.responseCode;
		const responseMessage = this.liquorLicenceResponseDetails?.responseMessage;

		const availableLiquorLicenceDetails = this.liquorLicenceResponseDetails?.data;
		if (availableLiquorLicenceDetails !== undefined) {
			const liquorLicenceDetailsArray = Object.values(availableLiquorLicenceDetails);
			const selectedLiquorLicenceDetails: LiquorLicenceDetails | undefined = liquorLicenceDetailsArray.find(
				liquorLicenceDetails => liquorLicenceDetails.uniqueId === uniqueId,
			);
			if (selectedLiquorLicenceDetails !== undefined) {
				this.selectedLiquorLicenceDetails = selectedLiquorLicenceDetails;
				this.drinksLicence = selectedLiquorLicenceDetails.licenceNumber;
				this.drinksLicenceStatus = selectedLiquorLicenceDetails.status;
				// eslint-disable-next-line max-len
				this.drinksLicenceLastUpdated = new Date(selectedLiquorLicenceDetails.dateModified ?? new Date(1001, 0, 1));
				// eslint-disable-next-line max-len
				const state = Enums.stateOptions[selectedLiquorLicenceDetails.serialState ?? this.drinksLicenceState] as Enums.state;
				this.drinksLicenceState = state;
			}

			const updatedLicenceDetails: Record<string, LiquorLicenceDetails> = {};
			for (const liquorLicenceDetails of liquorLicenceDetailsArray) {
				const isSelected = liquorLicenceDetails.uniqueId === uniqueId;
				// eslint-disable-next-line max-len
				updatedLicenceDetails[`${liquorLicenceDetails.uniqueId.toUpperCase()}::${isSelected ? 'TRUE' : 'FALSE'}`] = liquorLicenceDetails;
			}
			this.drinksLicenceInfo = JSON.stringify(updatedLicenceDetails);
			this.liquorLicenceResponseDetails = {
				data: updatedLicenceDetails,
				responseCode: responseCode ?? 0,
				responseMessage: responseMessage ?? '',
			};

			this.setFieldError('drinksLicence', false, '');
			this.setFieldError('drinksLicenceState', false, '');
		}
	}

	@action
	async runLiquorLicenceValidation() {
		this.liquorLicenceFinishedValidating = true;
		this.drinksLicence = this.drinksLicence ? this.drinksLicence.replace(/\W/g, '') : this.drinksLicence;

		if (this.drinksLicenceState === 'NONE') {
			this.liquorLicenceFinishedValidating = false;
			this.setFieldError('drinksLicenceState', false, '');
			if (this.drinksLicence) {
				this.setFieldError('drinksLicence', true, 'Licence state must be selected');
			} else {
				this.setFieldError('drinksLicence', false, '');
			}
			this.clearLiquorLicenceFields();
			return true;
		}

		if (!this.drinksLicence) {
			this.liquorLicenceFinishedValidating = false;
			this.setFieldError('drinksLicence', true, 'Licence number cannot be empty');
			this.clearLiquorLicenceFields();
			return false;
		}

		if (this.drinksLicence.length < 5) {
			this.liquorLicenceFinishedValidating = false;
			this.setFieldError('drinksLicence', true, 'Licence number must be at least 5 characters');
			this.clearLiquorLicenceFields();
			return false;
		}

		if (!isTypeOfState(this.drinksLicenceState)) {
			this.setFieldError('drinksLicence', false, '');
			this.setFieldError('drinksLicenceState', true, 'Licence issued state must be a valid state');
			this.clearLiquorLicenceFields();
			return false;
		}

		const response = await validateLiquorLicenceNumber(this.drinksLicence, this.drinksLicenceState, this.id);

		if (!response) {
			runInAction(() => {
				this.liquorLicenceFinishedValidating = true;
				this.clearLiquorLicenceFields();
				this.setFieldError('drinksLicence', true, 'Licence number not found');
				this.setFieldError('drinksLicenceState', true, 'Licence state not matched with the licence number.');
			});
			return false;
		}

		if (response.exception && !response.data) {
			runInAction(() => {
				this.liquorLicenceFinishedValidating = true;
				this.clearLiquorLicenceFields();
				this.setFieldError('drinksLicence', true, response.exception.exceptionDescription);
				this.setFieldError('drinksLicenceState', true, 'Licence state not matched with the licence number.');
			});
			return false;
		}

		// Record keys format is uniqueId::isSelected
		const parsedLicenceDetails = JSON.parse(response.data.licenceDetails) as Record<string, LiquorLicenceDetails>;

		if (!parsedLicenceDetails || Object.keys(parsedLicenceDetails).length < 1) {
			runInAction(() => {
				this.liquorLicenceFinishedValidating = true;
				this.clearLiquorLicenceFields();
				this.liquorLicenceResponseDetails = undefined;
				this.setFieldError('drinksLicence', true, 'Licence number not found');
				this.setFieldError('drinksLicenceState', true, 'Licence state not matched with the licence number.');
			});

			return false;
		}

		let selectedLiquorLicenceDetails: LiquorLicenceDetails | undefined;

		for (const [key, value] of Object.entries(parsedLicenceDetails)) {
			const isSelected = key.toUpperCase().split('::')[1] === 'TRUE';
			const uniqueId = key.toUpperCase().split('::')[0];

			if (isSelected && uniqueId === value.uniqueId) {
				selectedLiquorLicenceDetails = value;
			}
		}

		// if there is only one licence detail found, we can just set the liquor
		if (selectedLiquorLicenceDetails !== undefined && Object.keys(parsedLicenceDetails).length > 0) {
			runInAction(() => {
				this.liquorLicenceFinishedValidating = true;
				this.drinksLicence = selectedLiquorLicenceDetails?.licenceNumber ?? '-';
				// eslint-disable-next-line max-len
				this.drinksLicenceLastUpdated = new Date(selectedLiquorLicenceDetails?.dateModified ?? new Date(1001, 0, 1));
				this.drinksLicenceStatus = selectedLiquorLicenceDetails?.status ?? '-';
				this.drinksLicenceInfo = response.data.licenceDetails;
				this.liquorLicenceResponseDetails = {
					data: parsedLicenceDetails,
					responseCode: response.data.responseCode,
					responseMessage: response.data.responseMessage,
				};
				this.selectedLiquorLicenceDetails = selectedLiquorLicenceDetails;
				this.setFieldError('drinksLicence', false, '');
				this.setFieldError('drinksLicenceState', false, '');
			});

			return true;
		}

		// if there are multiple licence details found, we need to show the user a modal
		runInAction(() => {
			this.liquorLicenceFinishedValidating = true;
			this.clearLiquorLicenceFields();
			this.liquorLicenceResponseDetails = {
				data: parsedLicenceDetails,
				responseCode: response.data.responseCode,
				responseMessage: response.data.responseMessage,
			};
			this.setFieldError('drinksLicence', true, 'Multiple Licence Details Found Choose one');
			this.setFieldError('drinksLicenceState', false, '');
		});

		return false;
	}

	// function for running abn validation for this customer entity, and set values
	// for each of the fields that are returned from ABR
	@action
	async runAbnValidation() : Promise<boolean> {
		this.abnFinishedValidating = false;
		if (!this.abn) {
			this.setFieldError('abn', true, 'ABN cannot be empty');
			this.clearAbrFields();
			return false;
		}

		if (this.abn.replace(/\D/g, '').length < 11) {
			this.setFieldError('abn', true, 'The ABN must contain 11 digits');
			this.clearAbrFields();
			return false;
		}

		const response = await verifyAbn(this.abn);
		// since there was an await, we now runInAction from here on
		if (response.exception !== null) {
			runInAction(() => {
				this.abnFinishedValidating = true;
				this.clearAbrFields();
				if (response.exception.exceptionDescription.includes('No records found')) {
					this.entityStatus = 'NO RECORD';
				} else {
					this.entityStatus = response.exception.exceptionDescription;
				}
				this.setFieldError('abn', true, response.exception.exceptionDescription);
			});
			return false;
		}

		runInAction(() => {
			this.abnFinishedValidating = true;
			this.setFieldError('abn', false, '');
			// we now set the fields based off what was returned from ABR
			this.entityTypeCode = response.businessEntity.entityType.entityTypeCode;
			this.entityTypeDescription = response.businessEntity.entityType.entityDescription;
			if (this.entityTypeCode === 'IND') {
				const { givenName, otherGivenName, familyName } = response.businessEntity.legalName;
				this.entityName = [givenName, otherGivenName, familyName].join(' ');
			} else {
				this.entityName = response.businessEntity.mainName.organisationName;
			}
			this.registeredForGSTFrom = response.businessEntity.goodAndServicesTax?.effectiveFrom;
			this.locality = `${response.businessEntity.mainBusinessPhysicalAddress.postCode}, `
				+ `${response.businessEntity.mainBusinessPhysicalAddress.stateCode}`;
			this.activeSince = new Date(response.businessEntity.entityStatus.effectiveFrom);
			this.recordUpdated = new Date(response.businessEntity.recordLastUpdatedDate);
			this.abnActive = response.businessEntity.abn.isCurrentIndicator === 'Y';
			this.entityStatus = response.businessEntity.entityStatus.entityStatusCode;
			if (!!response.businessEntity.asicNumber && !this.acn) {
				this.acn = response.businessEntity.asicNumber;
				this.runAcnValidation();
			}
			if (this.entityStatus !== 'Active') {
				this.setFieldError('abn', true, 'The ABN must be currently active');
			}
			this.abrLastUpdated = moment().utc().toDate();
		});

		return true;
	}

	@action
	async runAcnValidation() : Promise<boolean> {
		this.acnFinishedValidating = false;
		if (!this.acn) {
			this.setFieldError('acn', false, '');
			this.clearAcnFields();
			return true;
		}
		const parsedACN = this.acn.replace(/\D/g, '');

		if (parsedACN.length < 9) {
			this.clearAcnFields();
			this.setFieldError('acn', true, 'The ACN must contain 9 digits');
			this.acnStatus = 'invalid';
			return false;
		}
		
		const response = await verifyAcn(this.acn);

		if (!!response && !!response.exception) {
			runInAction(() => {
				this.acnFinishedValidating = true;
				this.clearAcnFields();
				this.acnStatus = response.exception.exceptionDescription;
				this.setFieldError('acn', true, response.exception.exceptionDescription);
			});
			return false;
		}

		if (response.result.records.length === 0) {
			runInAction(() => {
				this.acnFinishedValidating = true;
				this.clearAcnFields();
				this.acnStatus = 'NO RECORD';
				this.setFieldError('acn', true, 'No records found');
			});
			return true;
		}
		const { records } = response.result;
		// valid records are ones with status REGD
		const validRecords = records.filter((x: { Status: string }) => x.Status === 'REGD');
		// ACN is valid if at least one record has REGD status
		if (validRecords.length === 0) {
			runInAction(() => {
				this.acnLastUpdated = moment().utc().toDate();
				this.acnFinishedValidating = true;
				// grab the first non REGD status
				const record = records.find((x: { Status: string }) => x.Status !== 'REGD');
				this.acnEntityTypeCode = record.Type;
				this.acnStatus = record.Status;
				this.acnEntityName = record['Company Name'];
				this.acnClass = record.Class;
				this.acnSubClass = record['Sub Class'];
				this.acnRegistrationDate = record['Date of Registration'];
				this.setFieldError('acn', true, 'The ACN must be currently active');
			});
			return true;
		}

		runInAction(() => {
			this.acnLastUpdated = moment().utc().toDate();
			this.acnFinishedValidating = true;
			this.acnStatus = 'REGD';
			const validRecord = records.find((x: { 'Current Name Indicator': string }) => x['Current Name Indicator']
					=== 'Y');
			this.acnEntityTypeCode = validRecord.Type;
			this.acnEntityName = validRecord['Company Name'];
			this.acnClass = validRecord.Class;
			this.acnSubClass = validRecord['Sub Class'];
			this.acnRegistrationDate = validRecord['Date of Registration'];
			this.setFieldError('acn', false, '');
		});
		return true;
	}
	
	@action
	checkAcnAndTrusteeAcnValue(key: "acn" | "trusteeACN") {
		const parsedKey =  key === "trusteeACN" ?  "Trustee ACN": "ACN";
		const comparedKey =  key === "acn" ? "Trustee ACN" : "ACN";

		// check if value are empty string or null;
		if (this.trusteeACN.length <= 0 || this.acn.length <= 0)
		{
			this.setFieldError(key, false, '');
			return true;
		}
		
		const parsedACN = this.acn.replace(/\D/g, '');
		const parsedTrusteeACN = this.trusteeACN.replace(/\D/g, '');
		
		if (parsedTrusteeACN === parsedACN) {
			this.setFieldError(key, true, `The ${parsedKey} cannot be the same as ${comparedKey}`);
			return false;
		}
		return true;
	}

	@action
	async runTrusteeACNValidation() : Promise<boolean> {
		this.trusteeACNFinishedValidating = false;
		if (!this.trusteeACN) {
			this.clearTrusteeAcnFields();
			this.setFieldError('trusteeACN', false, '');
			return true;
		}

		const parsedTrusteeACN = this.trusteeACN.replace(/\D/g, '');
		if (parsedTrusteeACN.length < 9) {
			this.clearTrusteeAcnFields();
			this.setFieldError('trusteeACN', true, 'The Trustee ACN must contain 9 digits');
			this.trusteeACNStatus = 'invalid';
			return false;
		}

		const response = await verifyAcn(this.trusteeACN);

		if (!!response && !!response.exception) {
			runInAction(() => {
				this.trusteeACNFinishedValidating = true;
				this.clearTrusteeAcnFields();
				this.trusteeACNStatus = response.exception.exceptionDescription;
				this.setFieldError('trusteeACN', true, response.exception.exceptionDescription);
			});
			return false;
		}

		if (response.result.records.length === 0) {
			runInAction(() => {
				this.trusteeACNFinishedValidating = true;
				this.clearTrusteeAcnFields();
				this.trusteeACNStatus = 'NO RECORD';
				this.setFieldError('trusteeACN', true, 'No records found');
			});
			return true;
		}
		const { records } = response.result;
		// valid records are ones with status
		const validRecords = records.filter((x: { Status: string }) => x.Status === 'REGD');
		// ACN is valid if at least one record has REGD status
		if (validRecords.length === 0) {
			runInAction(() => {
				this.trusteeACNLastUpdated = moment().utc().toDate();
				this.trusteeACNFinishedValidating = true;
				// grab the first non REGD
				const record = records.find((x: { Status: string }) => x.Status !== 'REGD');
				this.trusteeACNEntityTypeCode = record.Type;
				this.trusteeACNStatus = record.Status;
				this.trusteeACNEntityName = record['Company Name'];
				this.trusteeACNClass = record.Class;
				this.trusteeACNSubClass = record['Sub Class'];
				this.trusteeACNRegistrationDate = record['Date of Registration'];
				this.setFieldError('trusteeACN', true, 'The ACN must be currently active');
			});
			return true;
		}

		runInAction(() => {
			this.trusteeACNLastUpdated = moment().utc().toDate();
			this.trusteeACNFinishedValidating = true;
			this.trusteeACNStatus = 'REGD';
			const validRecord = records.find((x: { 'Current Name Indicator': string }) => x['Current Name Indicator']
				=== 'Y');
			this.trusteeACNEntityTypeCode = validRecord.Type;
			this.trusteeACNEntityName = validRecord['Company Name'];
			this.trusteeACNClass = validRecord.Class;
			this.trusteeACNSubClass = validRecord['Sub Class'];
			this.trusteeACNRegistrationDate = validRecord['Date of Registration'];
			this.setFieldError('trusteeACN', false, '');
		});
		return true;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CustomerEntity.prototype, 'created');
CRUD(modifiedAttr)(CustomerEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
