/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserAtbFileEntity from 'Models/Security/Acl/UserAtbFileEntity';
import VisitorsAtbFileEntity from 'Models/Security/Acl/VisitorsAtbFileEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IAtbFileEntityAttributes extends IModelAttributes {
	atbFileType: Enums.atbFileType;
	atbProcessingStatus: Enums.atbProcessingStatus;
	targetMonth: Date;
	fileName: string;
	addNewCustomers: boolean;
	atbJobStatus: Enums.atbJobStatus;

	businessEntityId: string;
	businessEntity: Models.BusinessEntity | Models.IBusinessEntityAttributes;
	userId: string;
	user: Models.UserEntity | Models.IUserEntityAttributes;
	atbRecords: Array<
		| Models.AtbRecordEntity
		| Models.IAtbRecordEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AtbFileEntity', 'ATB File')
// % protected region % [Customise your entity metadata here] end
export default class AtbFileEntity extends Model
	implements IAtbFileEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserAtbFileEntity(),
		new VisitorsAtbFileEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'ATB File Type'] off begin
	@observable
	@attribute<AtbFileEntity, Enums.atbFileType>()
	@CRUD({
		name: 'ATB File Type',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.atbFileTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.atbFileTypeOptions),
		displayFunction: (attr: Enums.atbFileType) => Enums.atbFileTypeOptions[attr],
	})
	public atbFileType: Enums.atbFileType;
	// % protected region % [Modify props to the crud options here for attribute 'ATB File Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'ATB Processing Status'] off begin
	@observable
	@attribute<AtbFileEntity, Enums.atbProcessingStatus>()
	@CRUD({
		name: 'ATB Processing Status',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.atbProcessingStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.atbProcessingStatusOptions),
		displayFunction: (attr: Enums.atbProcessingStatus) => Enums.atbProcessingStatusOptions[attr],
	})
	public atbProcessingStatus: Enums.atbProcessingStatus;
	// % protected region % [Modify props to the crud options here for attribute 'ATB Processing Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Target Month'] off begin
	/**
	 * Target Year and Month for the data. Day should always be 0.
	 */
	@observable
	@attribute<AtbFileEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Target Month',
		displayType: 'datepicker',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public targetMonth: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Target Month'] end

	// % protected region % [Modify props to the crud options here for attribute 'File name'] off begin
	@observable
	@attribute<AtbFileEntity, string>()
	@CRUD({
		name: 'File name',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public fileName: string;
	// % protected region % [Modify props to the crud options here for attribute 'File name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Add New Customers'] off begin
	/**
	 * Whether or not new customers should be added by default
	 */
	@observable
	@attribute<AtbFileEntity, boolean>()
	@CRUD({
		name: 'Add New Customers',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public addNewCustomers: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Add New Customers'] end

	// % protected region % [Modify props to the crud options here for attribute 'ATB Job Status'] off begin
	@observable
	@attribute<AtbFileEntity, Enums.atbJobStatus>()
	@CRUD({
		name: 'ATB Job Status',
		displayType: 'enum-combobox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.atbJobStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.atbJobStatusOptions),
		displayFunction: (attr: Enums.atbJobStatus) => Enums.atbJobStatusOptions[attr],
	})
	public atbJobStatus: Enums.atbJobStatus;
	// % protected region % [Modify props to the crud options here for attribute 'ATB Job Status'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] off begin
		name: 'Business Entity',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.BusinessEntity,
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] end
	})
	public businessEntityId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public businessEntity: Models.BusinessEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'User'] off begin
		name: 'User',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.UserEntity,
		// % protected region % [Modify props to the crud options here for reference 'User'] end
	})
	public userId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public user: Models.UserEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ATB Record'] off begin
		name: 'ATB Records',
		displayType: 'reference-multicombobox',
		order: 90,
		referenceTypeFunc: () => Models.AtbRecordEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'atbRecords',
			oppositeEntity: () => Models.AtbRecordEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ATB Record'] end
	})
	public atbRecords: Models.AtbRecordEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	public countAtbRecordErrors: number;

	@observable
	public countAtbRecordWarnings: number;

	@observable
	public processedAtbRecords: number = 0;

	@observable
	public totalAtbRecords: number = 0;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAtbFileEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAtbFileEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.atbFileType !== undefined) {
				this.atbFileType = attributes.atbFileType;
			}
			if (attributes.atbProcessingStatus !== undefined) {
				this.atbProcessingStatus = attributes.atbProcessingStatus;
			}
			if (attributes.targetMonth !== undefined) {
				if (attributes.targetMonth === null) {
					this.targetMonth = attributes.targetMonth;
				} else {
					this.targetMonth = moment(attributes.targetMonth).toDate();
				}
			}
			if (attributes.fileName !== undefined) {
				this.fileName = attributes.fileName;
			}
			if (attributes.addNewCustomers !== undefined) {
				this.addNewCustomers = attributes.addNewCustomers;
			}
			if (attributes.atbJobStatus !== undefined) {
				this.atbJobStatus = attributes.atbJobStatus;
			}
			if (attributes.businessEntityId !== undefined) {
				this.businessEntityId = attributes.businessEntityId;
			}
			if (attributes.businessEntity !== undefined) {
				if (attributes.businessEntity === null) {
					this.businessEntity = attributes.businessEntity;
				} else if (attributes.businessEntity instanceof Models.BusinessEntity) {
					this.businessEntity = attributes.businessEntity;
					this.businessEntityId = attributes.businessEntity.id;
				} else {
					this.businessEntity = new Models.BusinessEntity(attributes.businessEntity);
					this.businessEntityId = this.businessEntity.id;
				}
			}
			if (attributes.userId !== undefined) {
				this.userId = attributes.userId;
			}
			if (attributes.user !== undefined) {
				if (attributes.user === null) {
					this.user = attributes.user;
				} else if (attributes.user instanceof Models.UserEntity) {
					this.user = attributes.user;
					this.userId = attributes.user.id;
				} else {
					this.user = new Models.UserEntity(attributes.user);
					this.userId = this.user.id;
				}
			}
			if (attributes.atbRecords !== undefined && Array.isArray(attributes.atbRecords)) {
				for (const model of attributes.atbRecords) {
					if (model instanceof Models.AtbRecordEntity) {
						this.atbRecords.push(model);
					} else {
						this.atbRecords.push(new Models.AtbRecordEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		businessEntity {
			${Models.BusinessEntity.getAttributes().join('\n')}
		}
		user {
			${Models.UserEntity.getAttributes().join('\n')}
		}
		atbRecords {
			${Models.AtbRecordEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			atbRecords: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AtbFileEntity.prototype, 'created');
CRUD(modifiedAttr)(AtbFileEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
