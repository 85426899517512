/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsCustomersReferenceManyToMany from '../Security/Acl/VisitorsCustomersReferenceManyToMany';
import UserCustomersReferenceManyToMany from '../Security/Acl/UserCustomersReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IGroupsCustomersAttributes extends IModelAttributes {
	groupsId: string;
	customersId: string;

	groups: Models.GroupEntity | Models.IGroupEntityAttributes;
	customers: Models.CustomerEntity | Models.ICustomerEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('GroupsCustomers')
export default class GroupsCustomers
	extends Model
	implements IGroupsCustomersAttributes {
	public static acls: IAcl[] = [
		new VisitorsCustomersReferenceManyToMany(),
		new UserCustomersReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public groupsId: string;

	@observable
	@attribute()
	public customersId: string;

	@observable
	@attribute({ isReference: true })
	public groups: Models.GroupEntity;

	@observable
	@attribute({ isReference: true })
	public customers: Models.CustomerEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IGroupsCustomersAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.groupsId) {
				this.groupsId = attributes.groupsId;
			}
			if (attributes.customersId) {
				this.customersId = attributes.customersId;
			}

			if (attributes.groups) {
				if (attributes.groups instanceof Models.GroupEntity) {
					this.groups = attributes.groups;
					this.groupsId = attributes.groups.id;
				} else {
					this.groups = new Models.GroupEntity(attributes.groups);
					this.groupsId = this.groups.id;
				}
			} else if (attributes.groupsId !== undefined) {
				this.groupsId = attributes.groupsId;
			}

			if (attributes.customers) {
				if (attributes.customers instanceof Models.CustomerEntity) {
					this.customers = attributes.customers;
					this.customersId = attributes.customers.id;
				} else {
					this.customers = new Models.CustomerEntity(attributes.customers);
					this.customersId = this.customers.id;
				}
			} else if (attributes.customersId !== undefined) {
				this.customersId = attributes.customersId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
