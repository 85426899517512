/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserBusinessEntityCreditReportSettings from 'Models/Security/Acl/UserBusinessEntityCreditReportSettings';
import VisitorsBusinessEntityCreditReportSettings from 'Models/Security/Acl/VisitorsBusinessEntityCreditReportSettings';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IBusinessEntityCreditReportSettingsEntityAttributes extends IModelAttributes {
	isEnabled: boolean;

	businessEntityId: string;
	businessEntity: Models.BusinessEntity | Models.IBusinessEntityAttributes;
	providerProductOrganisationId: string;
	providerProductOrganisation: Models.ProviderProductOrganisationEntity | Models.IProviderProductOrganisationEntityAttributes;
	providerProductId: string;
	providerProduct: Models.ProviderProductEntity | Models.IProviderProductEntityAttributes;
	reportRequests: Array<
		| Models.ReportRequestEntity
		| Models.IReportRequestEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('BusinessEntityCreditReportSettingsEntity', 'Business Entity Credit Report Settings')
// % protected region % [Customise your entity metadata here] end
export default class BusinessEntityCreditReportSettingsEntity extends Model
	implements IBusinessEntityCreditReportSettingsEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserBusinessEntityCreditReportSettings(),
		new VisitorsBusinessEntityCreditReportSettings(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Is Enabled'] off begin
	@observable
	@attribute<BusinessEntityCreditReportSettingsEntity, boolean>()
	@CRUD({
		name: 'Is Enabled',
		displayType: 'checkbox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Enabled'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] off begin
		name: 'Business Entity',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.BusinessEntity,
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] end
	})
	public businessEntityId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public businessEntity: Models.BusinessEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Provider Product Organisation'] off begin
		name: 'Provider Product Organisation',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.ProviderProductOrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Provider Product Organisation'] end
	})
	public providerProductOrganisationId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public providerProductOrganisation: Models.ProviderProductOrganisationEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Provider Product'] off begin
		name: 'Provider Product',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.ProviderProductEntity,
		// % protected region % [Modify props to the crud options here for reference 'Provider Product'] end
	})
	public providerProductId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public providerProduct: Models.ProviderProductEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] off begin
		name: 'Report Requests',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.ReportRequestEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'reportRequests',
			oppositeEntity: () => Models.ReportRequestEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] end
	})
	public reportRequests: Models.ReportRequestEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IBusinessEntityCreditReportSettingsEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IBusinessEntityCreditReportSettingsEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.isEnabled !== undefined) {
				this.isEnabled = attributes.isEnabled;
			}
			if (attributes.businessEntityId !== undefined) {
				this.businessEntityId = attributes.businessEntityId;
			}
			if (attributes.businessEntity !== undefined) {
				if (attributes.businessEntity === null) {
					this.businessEntity = attributes.businessEntity;
				} else if (attributes.businessEntity instanceof Models.BusinessEntity) {
					this.businessEntity = attributes.businessEntity;
					this.businessEntityId = attributes.businessEntity.id;
				} else {
					this.businessEntity = new Models.BusinessEntity(attributes.businessEntity);
					this.businessEntityId = this.businessEntity.id;
				}
			}
			if (attributes.providerProductOrganisationId !== undefined) {
				this.providerProductOrganisationId = attributes.providerProductOrganisationId;
			}
			if (attributes.providerProductOrganisation !== undefined) {
				if (attributes.providerProductOrganisation === null) {
					this.providerProductOrganisation = attributes.providerProductOrganisation;
				} else if (attributes.providerProductOrganisation instanceof Models.ProviderProductOrganisationEntity) {
					this.providerProductOrganisation = attributes.providerProductOrganisation;
					this.providerProductOrganisationId = attributes.providerProductOrganisation.id;
				} else {
					this.providerProductOrganisation = new Models.ProviderProductOrganisationEntity(attributes.providerProductOrganisation);
					this.providerProductOrganisationId = this.providerProductOrganisation.id;
				}
			}
			if (attributes.providerProductId !== undefined) {
				this.providerProductId = attributes.providerProductId;
			}
			if (attributes.providerProduct !== undefined) {
				if (attributes.providerProduct === null) {
					this.providerProduct = attributes.providerProduct;
				} else if (attributes.providerProduct instanceof Models.ProviderProductEntity) {
					this.providerProduct = attributes.providerProduct;
					this.providerProductId = attributes.providerProduct.id;
				} else {
					this.providerProduct = new Models.ProviderProductEntity(attributes.providerProduct);
					this.providerProductId = this.providerProduct.id;
				}
			}
			if (attributes.reportRequests !== undefined && Array.isArray(attributes.reportRequests)) {
				for (const model of attributes.reportRequests) {
					if (model instanceof Models.ReportRequestEntity) {
						this.reportRequests.push(model);
					} else {
						this.reportRequests.push(new Models.ReportRequestEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		businessEntity {
			${Models.BusinessEntity.getAttributes().join('\n')}
		}
		providerProductOrganisation {
			${Models.ProviderProductOrganisationEntity.getAttributes().join('\n')}
		}
		providerProduct {
			${Models.ProviderProductEntity.getAttributes().join('\n')}
		}
		reportRequests {
			${Models.ReportRequestEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			reportRequests: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(BusinessEntityCreditReportSettingsEntity.prototype, 'created');
CRUD(modifiedAttr)(BusinessEntityCreditReportSettingsEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
