/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Symbols } from 'Symbols';
import { initValidators, IModelAttributeValidationError, ErrorType } from '../Util';
import { Model } from 'Models/Model';
// % protected region % [Add extra imports and exports here] on begin
// Validation function for determining if the input is a phone number
export function isPhone(phone: string): boolean {
	if (phone) {
		// eslint-disable-next-line max-len
		return /^(\d{8}|\d{10}|\+{0,1}\d{2}\s*\d{3}\s*\d{3}\s*\d{3}|\+{0,1}\d{2}\s*\d{4}\s*\d{4}|\d{4}\s*\d{3}\s*\d{3}|\d{4}\s*\d{4})$/.test(phone);
	}
	return true;
}
// % protected region % [Add extra imports and exports here] end

// % protected region % [Override validate here] off begin
export default function validate(
	validatorName: string,
	validatorFunction: (property: any, target: any) => Promise<string | null>,
) {
	return (target: Record<string | symbol, any>, key: string) => {
		initValidators(target, key);
		target[Symbols.validatorMap][key].push(validatorName);
		target[Symbols.validator].push(
			(model: Model): Promise<IModelAttributeValidationError | null> => new Promise(resolve => {
				validatorFunction(model[key], model).then(error => {
					if (error === null) {
						resolve(null);
						return;
					}

					resolve({
						errorType: ErrorType.INVALID,
						errorMessage: error,
						attributeName: key,
						target: model,
					});
				});
			}),
		);
	};
}
// % protected region % [Override validate here] end
